import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../../root.types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import Note from '../../../application/models/note/note'
import { NoteType } from '../../../application/utils/note.type'

/**
 * Note (Diagnostic and Evolution) types
 */
export const NoteTypes = createTypes(`
    RESET_LIST
    RESET_CREATE
    
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE
    CHANGE_DIALOG_CREATE
    
    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE
    
    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE
              
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE
    
    CHANGE_DIALOG_REMOVE
    
    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE    
    `,
    {
        prefix: '@patient.note/'
    }
)

/**
 * Action Types
 */
export interface IActionFind {
    readonly patientId: string
    readonly type: NoteType
    readonly noteId: string
}

export interface IActionCreate {
    readonly note: Note
}

export interface IActionLoad {
    readonly patientId: string
    readonly type: NoteType
    readonly paginator?: IPaginator
    readonly filter?: TimeSeriesSimpleFilter
}

export interface IActionRemove {
    readonly patientId: string
    readonly type: NoteType
    readonly ids: string[]
}

/**
 * Activities State
 */
interface IListState extends IComponentState {
    readonly data: Note[]
    readonly paginator: IPaginator

}

export interface IRemoveState extends IComponentState {
    readonly ids: string[]
    readonly dialog: boolean
}

export interface ICreateState extends IComponentState {
    readonly note: Note
    readonly dialog: boolean
}


/**
 * State type
 */
export interface INoteState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
}
