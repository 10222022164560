import { JsonUtils } from '../../utils/json.util'

export default class Device {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _name: string | undefined
    private _address: string | undefined
    private _type: string | undefined
    private _model_number: string | undefined
    private _patient_id: string | undefined
    private _manufacturer: string | undefined
    private _last_sync: string | undefined
    private _charge_level: number | undefined
    private _is_authenticated: boolean | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get address(): string | undefined {
        return this._address
    }

    set address(value: string | undefined) {
        this._address = value
    }

    get type(): string | undefined {
        return this._type
    }

    set type(value: string | undefined) {
        this._type = value
    }

    get model_number(): string | undefined {
        return this._model_number
    }

    set model_number(value: string | undefined) {
        this._model_number = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get manufacturer(): string | undefined {
        return this._manufacturer
    }

    set manufacturer(value: string | undefined) {
        this._manufacturer = value
    }

    get last_sync(): string | undefined {
        return this._last_sync
    }

    set last_sync(value: string | undefined) {
        this._last_sync = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get charge_level(): number | undefined {
        return this._charge_level
    }

    set charge_level(value: number | undefined) {
        this._charge_level = value
    }

    get is_authenticated(): boolean | undefined {
        return this._is_authenticated
    }

    set is_authenticated(value: boolean | undefined) {
        this._is_authenticated = value
    }

    public fromJSON(json: any): Device {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.address !== undefined) {
            this.address = json.address
        }

        if (json.type !== undefined) {
            this.type = json.type
        }

        if (json.model_number !== undefined) {
            this.model_number = json.model_number
        }

        if (json.manufacturer !== undefined) {
            this.manufacturer = json.manufacturer
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        if (json.last_sync !== undefined) {
            this.last_sync = json.last_sync
        }

        if (json.charge_level !== undefined) {
            this.charge_level = json.charge_level
        }

        if (json.is_authenticated !== undefined) {
            this.is_authenticated = json.is_authenticated
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            name: this.name ? this.name : undefined,
            address: this.address ? this.address : undefined,
            type: this.type ? this.type : undefined,
            model_number: this.model_number ? this.model_number : undefined,
            manufacturer: this.manufacturer ? this.manufacturer : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined,
            last_sync: this.last_sync ? this.last_sync : undefined,
            charge_level: this.charge_level ? this.charge_level : undefined,
            is_authenticated: this.is_authenticated ? this.is_authenticated : undefined
        }
    }

    public formattedAddress(): string {
        if (this.address?.includes(':')) {
            return this.address
        }
        return this.address
                ?.substr(0, 16)
                ?.match(/.{1,2}/g)
                ?.join(':')
            || ''
    }
}
