import Measurement from './measurement'
import { TypeMeasurement } from './measurements.last'
import { YesOrNo } from '../users/sociodemographic'

export default class Weight extends Measurement {

    private _value: number | undefined
    private _body_fat: number | undefined
    private _bmi: number | undefined
    private _annual_variation: YesOrNo | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.WEIGHT
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get body_fat(): number | undefined {
        return this._body_fat
    }

    set body_fat(value: number | undefined) {
        this._body_fat = value
    }

    get bmi(): number | undefined {
        return this._bmi
    }

    set bmi(value: number | undefined) {
        this._bmi = value
    }

    get annual_variation(): YesOrNo | undefined {
        return this._annual_variation
    }

    set annual_variation(value: YesOrNo | undefined) {
        this._annual_variation = value
    }

    public fromJSON(json: any): Weight {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (json.body_fat !== undefined) {
            this.body_fat = json.body_fat
        }

        if (json.bmi !== undefined) {
            this.bmi = json.bmi
        }

        if (json.annual_variation !== undefined) {
            this.annual_variation = json.annual_variation
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined,
            body_fat: this.body_fat ? this.body_fat : undefined,
            bmi: this.bmi ? this.bmi : undefined,
            annual_variation: this.annual_variation ? this.annual_variation : undefined
        }
    }
}
