import { all, apply, call, put, takeEvery, takeLatest } from 'redux-saga/effects'

import associateService from '../../../../services/users/associates'
import avatarService from '../../../../services/users/avatar'
import { IActionType, IAxiosResponse } from '../../root.types'
import { AssociatesTypes, IActionLoad } from './types'

import {
    associatePatientFailure,
    associatePatientSuccess,
    disassociatePatientFailure,
    disassociatePatientSuccess,
    loadAssociatesFailure,
    loadAssociatesSuccess,
    loadByCaregiverIdFailure,
    loadByCaregiverIdSuccess,
    loadMoreAssociatesFailure,
    loadMoreAssociatesSuccess,
    loadMoreByCaregiverIdFailure,
    loadMoreByCaregiverIdSuccess,
    loadMultipleAvatarFailure,
    loadMultipleAvatarSuccess,
    removeAssociatedSuccess,
    removeSuccess
} from './actions'
import { SnackBarMessageType } from '../../../../components/snackbar'
import { open } from '../../snack.bar/actions'
import userService from '../../../../services/users'
import { loadFailure, loadMoreFailure, loadMoreSuccess, loadSuccess, removeFailure } from '../actions'
import Patient from '../../../application/models/users/patient'
import { getMultipleAvatar as getMultipleAvatarRoot } from '../sagas'
import { IActionRemove } from '../types'
import localStorageService from '../../../../services/local.storage'
import { selectPatient } from '../../layout/actions'

function* getMultipleAvatar(users: string[]) {
    for (const userId of users) {
        try {
            const response: any = yield apply(avatarService, avatarService.get, [userId])
            yield put<any>(loadMultipleAvatarSuccess(userId, response))
        } catch (e) {
            yield put(loadMultipleAvatarFailure(userId))
        }
    }
}

function* getAssociatesUser(action: IActionType<IActionLoad>) {
    try {
        const { userId, userType, paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(associateService,
            associateService.getAllByHealthProfessionalId, [userId, userType, paginator])
        yield put<any>(loadAssociatesSuccess(userType, response))
        const usersId: string[] = response?.data?.map((adm: any) => adm?.id || '') || []
        yield call(getMultipleAvatar, usersId)
    } catch (e) {
        yield put(loadAssociatesFailure())
    }
}

function* getMoreAssociates(action: IActionType<IActionLoad>) {
    try {
        const { userId, userType, paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(associateService,
            associateService.getAllByHealthProfessionalId, [userId, userType, paginator])
        yield put<any>(loadMoreAssociatesSuccess(userType, response))
        const usersId: string[] = response?.data?.map((adm: any) => adm?.id || '') || []
        yield call(getMultipleAvatar, usersId)
    } catch (e) {
        yield put(loadMoreAssociatesFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(userService, userService.remove, [id])
        yield put<any>(removeAssociatedSuccess(id))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadByHealthProfessionalId(action: IActionType) {
    try {
        const { healthProfessionalId, type, paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            associateService,
            associateService.getAllByHealthProfessionalId,
            [healthProfessionalId, type, paginator]
        )
        yield put<any>(loadSuccess(response))
        const usersId: string[] = response?.data?.map((user: any) => user?.id || '') || []
        yield call(getMultipleAvatarRoot, usersId)
    } catch (e) {
        yield put(loadFailure())
    }
}

function* loadMoreByHealthProfessionalId(action: IActionType) {
    try {
        const { healthProfessionalId, type, paginator } = action.payload
        const response: IAxiosResponse<any[]> = yield apply(
            associateService,
            associateService.getAllByHealthProfessionalId,
            [healthProfessionalId, type, paginator]
        )
        yield put<any>(loadMoreSuccess(response))
        const usersId: string[] = response?.data?.map((user: any) => user?.id || '') || []
        yield call(getMultipleAvatarRoot, usersId)
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* removeUser(action: IActionType<IActionRemove>) {
    try {
        const { id } = action.payload
        yield apply(userService, userService.remove, [id])
        yield put<any>(removeSuccess(id))
        /**
         * Check for patient selection remover if patient has been deleted.
         */
        try {
            const localPatient: any = localStorageService.getItem('patient')
            const patientSelected: Patient = new Patient().fromJSON(JSON.parse(localPatient))
            if (patientSelected.id === id) {
                yield put<any>(selectPatient(new Patient()))
            }
        } catch (err) {
            // not implemented
        }
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.REMOVE.MESSAGE'))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadByCaregiverId(action: IActionType) {
    try {
        const { caregiverId, paginator } = action.payload
        const response: IAxiosResponse<any[]>
            = yield apply(
            associateService,
            associateService.getAllByCaregiverId,
            [caregiverId, paginator]
        )
        yield put(loadByCaregiverIdSuccess(response))
        yield call(getPatientsMultipleAvatar, response.data)
    } catch (e) {
        yield put(loadByCaregiverIdFailure())
    }
}

function* loadMoreByCaregiverId(action: IActionType) {
    try {
        const { caregiverId, paginator } = action.payload
        const response: IAxiosResponse<Patient[]> = yield apply(
            associateService,
            associateService.getAllByCaregiverId,
            [caregiverId, paginator]
        )
        yield put<any>(loadMoreByCaregiverIdSuccess(response))
        yield call(getPatientsMultipleAvatar, response.data)
    } catch (e) {
        yield put(loadMoreByCaregiverIdFailure())
    }
}

function* getPatientsMultipleAvatar(users: any[]) {
    for (const user of users) {
        const userId = user.id
        try {
            const response: any = yield apply(avatarService, avatarService.get, [userId])
            yield put<any>(loadMultipleAvatarSuccess(userId, response))
        } catch (e) {
            yield put(loadMultipleAvatarFailure(userId))
        }
    }
}

function* associatePatient(action: IActionType) {
    const { userType, userId, patientId } = action.payload
    try {
        yield apply(associateService, associateService.associatePatient, [userType, userId, patientId])
        yield put<any>(associatePatientSuccess(userType, userId, patientId))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.PATIENT.SUCCESSFULLY_ASSOCIATED'))
    } catch (e) {
        yield put(associatePatientFailure(patientId))
    }
}

function* disassociatePatient(action: IActionType) {
    const { userType, userId, patientId } = action.payload
    try {
        yield apply(associateService, associateService.disassociatePatient, [userType, userId, patientId])
        yield put<any>(disassociatePatientSuccess(userType, userId, patientId))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.PATIENT.SUCCESSFULLY_DISASSOCIATED'))
    } catch (e) {
        yield put(disassociatePatientFailure(patientId))
    }
}

export default function* associatesSaga() {
    return yield all([
        takeEvery(AssociatesTypes.LOAD_ASSOCIATES_REQUEST, getAssociatesUser),
        takeEvery(AssociatesTypes.LOAD_MORE_ASSOCIATES_REQUEST, getMoreAssociates),
        takeLatest(AssociatesTypes.REMOVE_ASSOCIATED_REQUEST, remove),


        takeLatest(AssociatesTypes.LOAD_BY_HEALTH_PROFESSIONAL_ID, loadByHealthProfessionalId),
        takeEvery(AssociatesTypes.LOAD_MORE_BY_HEALTH_PROFESSIONAL_ID, loadMoreByHealthProfessionalId),
        takeEvery(AssociatesTypes.REMOVE_REQUEST, removeUser),
        takeLatest(AssociatesTypes.LOAD_BY_CAREGIVER_ID_REQUEST, loadByCaregiverId),
        takeEvery(AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_REQUEST, loadMoreByCaregiverId),
        takeEvery(AssociatesTypes.ASSOCIATE_PATIENT_REQUEST, associatePatient),
        takeEvery(AssociatesTypes.DISASSOCIATE_PATIENT_REQUEST, disassociatePatient)
    ])
}
