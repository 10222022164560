import { Reducer } from 'redux'
import { createReducer } from 'reduxsauce'
import { IPatientTimeSeriesState, TimeSeriesTypes } from './types'
import { failure, IActionType, request, success } from '../../root.types'
import TimeSeries from '../../../application/models/time.series/time.series'

const INITIAL_STATE: IPatientTimeSeriesState = {
    dialog: {
        dialog: false,
        date: ''
    },
    view: {
        timeseries: new TimeSeries(),
        loading: false,
        success: false,
        error: false
    },
    chart: {
        timeseries: new TimeSeries(),
        loading: false,
        success: false,
        error: false
    },
    list: {
        timeseries: new TimeSeries(),
        loading: false,
        success: false,
        error: false
    }
}

export const changeDialogTimeSeries = (state: IPatientTimeSeriesState = INITIAL_STATE, action: IActionType<{
    dialog: boolean,
    date: string
}>) => {
    const { dialog, date } = action.payload
    return { ...state, dialog: { ...state.dialog, dialog, date } }
}

export const loadTimeSeriesRequest = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, chart: request(state.chart) }
}

export const loadTimeSeriesSuccess = (state: IPatientTimeSeriesState = INITIAL_STATE, action: IActionType<{ timeseries: TimeSeries }>) => {
    const { timeseries } = action.payload
    return { ...state, chart: success({ ...state.chart, timeseries }) }
}

export const loadTimeSeriesFailure = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, chart: failure(state.chart) }
}

export const loadListTimeSeriesRequest = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadListTimeSeriesSuccess = (
    state: IPatientTimeSeriesState = INITIAL_STATE,
    action: IActionType<{ timeseries: TimeSeries }>
) => {
    const { timeseries } = action.payload
    return { ...state, list: success({ ...state.list, timeseries }) }
}

export const loadListTimeSeriesFailure = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadListMoreTimeSeriesSuccess = (
    state: IPatientTimeSeriesState = INITIAL_STATE,
    action: IActionType<{ timeseries: TimeSeries }>
) => {
    const { timeseries } = action.payload
    const stateTimes = state.list.timeseries
    if (timeseries?.data_set?.length && stateTimes?.data_set) {
        stateTimes.data_set = timeseries.data_set?.concat(stateTimes.data_set)
    }
    return { ...state, list: success({ ...state.list, timeseries: stateTimes }) }
}

export const viewTimeSeriesRequest = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, view: request(state.view) }
}
export const viewTimeSeriesSuccess = (
    state: IPatientTimeSeriesState = INITIAL_STATE,
    action: IActionType<{ timeseries: TimeSeries }>
) => {
    const { timeseries } = action.payload
    return { ...state, view: success({ ...state.view, timeseries }) }
}
export const viewTimeSeriesFailure = (state: IPatientTimeSeriesState = INITIAL_STATE) => {
    return { ...state, view: failure(state.view) }
}

const reducer: Reducer<IPatientTimeSeriesState> = createReducer<IPatientTimeSeriesState>(INITIAL_STATE, {
    [TimeSeriesTypes.CHANGE_DIALOG_TIMESERIES]: changeDialogTimeSeries,

    [TimeSeriesTypes.VIEW_TIMESERIES_REQUEST]: viewTimeSeriesRequest,
    [TimeSeriesTypes.VIEW_TIMESERIES_SUCCESS]: viewTimeSeriesSuccess,
    [TimeSeriesTypes.VIEW_TIMESERIES_FAILURE]: viewTimeSeriesFailure,

    [TimeSeriesTypes.LOAD_TIMESERIES_REQUEST]: loadTimeSeriesRequest,
    [TimeSeriesTypes.LOAD_TIMESERIES_SUCCESS]: loadTimeSeriesSuccess,
    [TimeSeriesTypes.LOAD_TIMESERIES_FAILURE]: loadTimeSeriesFailure,

    [TimeSeriesTypes.LIST_TIMESERIES_REQUEST]: loadListTimeSeriesRequest,
    [TimeSeriesTypes.LIST_TIMESERIES_SUCCESS]: loadListTimeSeriesSuccess,
    [TimeSeriesTypes.LIST_TIMESERIES_FAILURE]: loadListTimeSeriesFailure,

    [TimeSeriesTypes.LIST_MORE_TIMESERIES_REQUEST]: loadListTimeSeriesRequest,
    [TimeSeriesTypes.LIST_MORE_TIMESERIES_SUCCESS]: loadListMoreTimeSeriesSuccess,
    [TimeSeriesTypes.LIST_MORE_TIMESERIES_FAILURE]: loadListTimeSeriesFailure
})

export default reducer
