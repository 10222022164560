import { action } from 'typesafe-actions'
import { INewReportRequest, ReportTypes } from './types'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import TimeSeries from '../../../application/models/time.series/time.series'

export const reset = () => action(ReportTypes.RESET)

/* Actions for load steps*/

export const loadStepsRequest = (patientId: string, startDate: Date, endDate: Date) => action(
    ReportTypes.LOAD_STEPS_REQUEST,
    { patientId, startDate, endDate }
)

export const loadStepsSuccess = (data: TimeSeries) => action(ReportTypes.LOAD_STEPS_SUCCESS, { data })

export const loadStepsFailure = () => action(ReportTypes.LOAD_STEPS_FAILURE)

/* Actions for load active minutes */

export const loadActiveMinutesRequest = (patientId: string, startDate: Date, endDate: Date) => action(
    ReportTypes.LOAD_ACTIVE_MINUTES_REQUEST,
    { patientId, startDate, endDate }
)

export const loadActiveMinutesSuccess = (data: TimeSeries) => action(ReportTypes.LOAD_ACTIVE_MINUTES_SUCCESS, { data })

export const loadActiveMinutesFailure = () => action(ReportTypes.LOAD_ACTIVE_MINUTES_FAILURE)

/* Actions for load heart rate */

export const loadHeartRateRequest = (patientId: string, startDate: Date, endDate: Date) => action(
    ReportTypes.LOAD_HEART_RATE_REQUEST,
    { patientId, startDate, endDate }
)

export const loadHeartRateSuccess = (data: IntradayHeartRate[]) => action(ReportTypes.LOAD_HEART_RATE_SUCCESS, { data })

export const loadHeartRateFailure = () => action(ReportTypes.LOAD_HEART_RATE_FAILURE)

export const changeDialog = (dialog: boolean) => {
    const array: any[] = [action(ReportTypes.CHANGE_DIALOG, { dialog })]
    if (dialog) {
        array.push(resetReportView())
    }
    return array
}

export const newReportRequest = (payload: INewReportRequest) => action(ReportTypes.NEW_REPORT_REQUEST, payload)

export const changeReportView = (field: string, value: boolean) => action(ReportTypes.CHANGE_REPORT_VIEW, {
    field,
    value
})

export const resetReportView = () => action(ReportTypes.RESET_REPORT_VIEW)
