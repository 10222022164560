import { JsonUtils } from '../../../../utils/json.util'
import { TimeSeriesInterval } from '../intraday.summary'
import HeartRateZone from '../../heart.rate/heart.rate.zone'

export default class IntradayHeartRateSummary {
    private _start_time: string | undefined
    private _end_time: string | undefined
    private _min:number | undefined
    private _max:number | undefined
    private _average:number | undefined
    private _interval: TimeSeriesInterval | undefined
    private _zones: HeartRateZone | undefined

    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        this._start_time = value
    }

    get end_time(): string | undefined {
        return this._end_time
    }

    set end_time(value: string | undefined) {
        this._end_time = value
    }

    get interval(): TimeSeriesInterval | undefined {
        return this._interval
    }

    set interval(value: TimeSeriesInterval | undefined) {
        this._interval = value
    }

    get min(): number | undefined {
        return this._min
    }

    set min(value: number | undefined) {
        this._min = value
    }

    get max(): number | undefined {
        return this._max
    }

    set max(value: number | undefined) {
        this._max = value
    }

    get average(): number | undefined {
        return this._average
    }

    set average(value: number | undefined) {
        this._average = value
    }

    get zones(): HeartRateZone | undefined {
        return this._zones
    }

    set zones(value: HeartRateZone | undefined) {
        this._zones = value
    }

    public fromJSON(json: any): IntradayHeartRateSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.end_time !== undefined) {
            this.end_time = json.end_time
        }

        if (json.interval !== undefined) {
            this.interval = json.interval
        }

        if (json.min !== undefined) {
            this.min = json.min
        }

        if (json.max !== undefined) {
            this.max = json.max
        }

        if (json.average !== undefined) {
            this.average = json.average
        }
        if (json.zones !== undefined) {
            this.zones = new HeartRateZone().fromJSON(json.zones)
        }

        return this
    }

    public toJSON(): any {
        return {
            start_time: this.start_time ? this.start_time : undefined,
            end_time: this.end_time ? this.end_time : undefined,
            min: this.min ? this.min : undefined,
            max: this.max ? this.max : undefined,
            average: this.average ? this.average : undefined,
            interval: this.interval ? this.interval : undefined,
            zones: this.zones ? this.zones.toJSON() : undefined
        }
    }
}
