import React, { Component } from 'react'

import { Provider } from 'react-redux'

import ThemeProviderComponent from './theme.provider'
import { Store } from 'redux'

interface IProps {
    readonly store: Store
}

class App extends Component<IProps> {
    public render() {
        const { store } = this.props
        return <Provider store={store}>
            <ThemeProviderComponent/>
        </Provider>
    }
}

export default App
