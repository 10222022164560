import { action } from 'typesafe-actions'
import { PatientTypes } from './types'
import Goal from '../../../application/models/users/goals'

export const saveGoalsRequest = (patientId: string, goals: Goal) => action(
    PatientTypes.SAVE_GOALS_REQUEST,
    { patientId, goals }
)

export const saveGoalsSuccess = () => action(PatientTypes.SAVE_GOALS_SUCCESS)

export const saveGoalsFailure = () => action(PatientTypes.SAVE_GOALS_FAILURE)
