import Note from '../note/note'
import { NoteType } from '../../utils/note.type'

export default class Diagnosis extends Note {

    constructor() {
        super()
        this._type = NoteType.DIAGNOSTIC
    }

    public fromJSON(json: any): Diagnosis {
        super.fromJSON(json)
        return this
    }

}
