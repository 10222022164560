import axiosInstance from './axios'
import { IAxiosResponse } from '../store/ducks/root.types'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'
import { IDashboardData, ITimeSeriesData } from '../store/ducks/patient/types'
import TimeSeries, { TimeSeriesType } from '../store/application/models/time.series/time.series'
import HeartRate from '../store/application/models/time.series/heart.rate/heart.rate'
import { TimeSeriesIntervalFilter } from '../store/application/models/time.series/filters/interval.filter'
import IntradayTimeSeries from '../store/application/models/time.series/intraday/intraday.time.series'
import IntradayHeartRate from '../store/application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import { TimeSeriesInterval } from '../store/application/models/time.series/intraday/intraday.summary'
import { TimeSeriesFullFilter } from '../store/application/models/time.series/filters/full.filter'

class TimeSeriesService {

    constructor(private apiVersion: string = 'v1') {
    }

    public getAll(patientId: string, filter: TimeSeriesSimpleFilter): Promise<ITimeSeriesData> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/date/${filter.start_date}/${filter.end_date}/timeseries`)
            .then((response: IAxiosResponse<IDashboardData>) => {
                const { calories, steps, active_minutes, heart_rate, distance } = response.data
                return {
                    steps: new TimeSeries().fromJSON(steps),
                    calories: new TimeSeries().fromJSON(calories),
                    active_minutes: new TimeSeries().fromJSON(active_minutes),
                    distance: new TimeSeries().fromJSON(distance),
                    heart_rate: new HeartRate().fromJSON(heart_rate)
                }
            })
    }

    public getResource(patientId: string, resource: TimeSeriesType, filter: TimeSeriesSimpleFilter)
        : Promise<TimeSeries | HeartRate> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/${resource}/date/${filter.start_date}/${filter.end_date}/timeseries`)
            .then((response: IAxiosResponse<TimeSeries | HeartRate>) => {
                const { data } = response
                if (resource === TimeSeriesType.HEART_RATE) {
                    return new HeartRate().fromJSON(data)
                }
                return new TimeSeries().fromJSON(data)
            })
    }

    public getResourceWithInterval(patientId: string, originalResource: TimeSeriesType, filter: TimeSeriesIntervalFilter)
        : Promise<IntradayTimeSeries | IntradayHeartRate> {
        const resource: string = originalResource === TimeSeriesType.HEART_RATE_VARIABILITY
            ? TimeSeriesType.HEART_RATE : originalResource
        const interval: TimeSeriesInterval | undefined = filter.interval
        const url: string = `${this.apiVersion}/patients/${patientId}/${resource}/date/${filter.date}/interval/${interval}/timeseries`
        return axiosInstance
            .get(url)
            .then((response: IAxiosResponse<IntradayTimeSeries | IntradayHeartRate>) => {
                const { data } = response
                if (resource === TimeSeriesType.HEART_RATE) {
                    return new IntradayHeartRate().fromJSON(data)
                }
                return new IntradayTimeSeries().fromJSON(data)
            })
    }

    public getResourceWithTime(patientId: string, resource: TimeSeriesType, filter: TimeSeriesFullFilter)
        : Promise<IntradayTimeSeries | IntradayHeartRate> {
        const interval: TimeSeriesInterval | undefined = filter.interval
        const url: string = `${this.apiVersion}/patients/${patientId}/${resource}/date/${filter.start_date}/${filter.end_date}/time` +
            `/${filter.start_time}/${filter.end_time}/interval/${interval}/timeseries`
        return axiosInstance
            .get(url)
            .then((response: IAxiosResponse<IntradayTimeSeries | IntradayHeartRate>) => {
                const { data } = response
                if (resource === TimeSeriesType.HEART_RATE) {
                    return new IntradayHeartRate().fromJSON(data)
                }
                return new IntradayTimeSeries().fromJSON(data)
            })
    }
}

export default new TimeSeriesService()
