import Note from '../note/note'
import { NoteType } from '../../utils/note.type'

export default class Evolution extends Note {

    constructor() {
        super()
        this._type = NoteType.EVOLUTION
    }

    public fromJSON(json: any): Evolution {
        super.fromJSON(json)
        return this
    }

}
