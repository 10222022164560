import Measurement from "./measurement"
import { TypeMeasurement } from "./measurements.last"

export default class WaistCircumference extends Measurement {

    private _value: number | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.WAIST_CIRCUMFERENCE
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    public fromJSON(json: any): WaistCircumference {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = json.value
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined
        }
    }
}
