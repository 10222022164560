import { JsonUtils } from '../../../utils/json.util'
import SleepSummaryData from './summary.data'

export default class SleepStagesSummary {
    private _deep: SleepSummaryData | undefined
    private _light: SleepSummaryData | undefined
    private _rem: SleepSummaryData | undefined
    private _awake: SleepSummaryData | undefined

    get deep(): SleepSummaryData | undefined {
        return this._deep
    }

    set deep(value: SleepSummaryData | undefined) {
        this._deep = value
    }

    get light(): SleepSummaryData | undefined {
        return this._light
    }

    set light(value: SleepSummaryData | undefined) {
        this._light = value
    }

    get rem(): SleepSummaryData | undefined {
        return this._rem
    }

    set rem(value: SleepSummaryData | undefined) {
        this._rem = value
    }

    get awake(): SleepSummaryData | undefined {
        return this._awake
    }

    set awake(value: SleepSummaryData | undefined) {
        this._awake = value
    }

    public fromJSON(json: any): SleepStagesSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.deep !== undefined) {
            this.deep = new SleepSummaryData().fromJSON(json.deep)
        }

        if (json.light !== undefined) {
            this.light = new SleepSummaryData().fromJSON(json.light)
        }

        if (json.rem !== undefined) {
            this.rem = new SleepSummaryData().fromJSON(json.rem)
        }

        if (json.awake !== undefined) {
            this.awake = new SleepSummaryData().fromJSON(json.awake)
        }

        return this
    }

    public toJSON(): any {
        return {
            deep: this.deep ? this.deep.toJSON(): undefined,
            light: this.light ? this.light.toJSON(): undefined,
            rem: this.rem ? this.rem.toJSON(): undefined,
            awake: this.awake ? this.awake.toJSON(): undefined
        }
    }
}
