import { JsonUtils } from '../../../utils/json.util'

export default class HeartRateData {
    private _min: number | undefined
    private _max: number | undefined
    private _duration: number | undefined
    private _calories: number | undefined

    get min(): number | undefined {
        return this._min
    }

    set min(value: number | undefined) {
        this._min = value
    }

    get max(): number | undefined {
        return this._max
    }

    set max(value: number | undefined) {
        this._max = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    get calories(): number | undefined {
        return this._calories
    }

    set calories(value: number | undefined) {
        this._calories = value
    }

    public fromJSON(json: any): HeartRateData {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.min !== undefined) {
            this.min = json.min
        }

        if (json.max !== undefined) {
            this.max = json.max
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        if (json.calories !== undefined) {
            this.calories = json.calories
        }
        return this
    }

    public toJSON(): any {
        return {
            min: this.min ? this.min : undefined,
            max: this.max ? this.max : undefined,
            duration: this.duration ? this.duration : undefined,
            calories: this.calories ? this.calories : undefined
        }
    }
}
