import axiosInstance from '../axios'
import Goal from '../../store/application/models/users/goals'
import Patient from '../../store/application/models/users/patient'
import { AxiosResponse } from 'axios'

class PatientService {

    constructor(private apiVersion: string = 'v1') {
    }

    public saveGoals(patientId: string, goals: Goal): Promise<Patient> {
        return axiosInstance
            .put(`${this.apiVersion}/patients/${patientId}/goals`, goals.toJSON())
            .then((response: AxiosResponse<Patient>) => new Patient().fromJSON(response.data))
    }

}

export default new PatientService()
