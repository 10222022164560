import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../../root.types'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import TimeSeries from '../../../application/models/time.series/time.series'
import Sleep from '../../../application/models/activity/sleep/sleep'
import Report from '../../../application/models/report/report'

/**
 * Patient types
 */
export const ReportTypes = createTypes(`
    RESET
    
    LOAD_STEPS_REQUEST   
    LOAD_STEPS_SUCCESS   
    LOAD_STEPS_FAILURE  
       
    LOAD_ACTIVE_MINUTES_REQUEST   
    LOAD_ACTIVE_MINUTES_SUCCESS   
    LOAD_ACTIVE_MINUTES_FAILURE
       
    LOAD_HEART_RATE_REQUEST   
    LOAD_HEART_RATE_SUCCESS   
    LOAD_HEART_RATE_FAILURE
    
    CHANGE_DIALOG
    
    NEW_REPORT_REQUEST
    CHANGE_REPORT_VIEW
    RESET_REPORT_VIEW
    `,
    {
        prefix: '@patient.report/'
    }
)

/**
 * Action Types
 */
export interface IActionLoadReport {
    readonly patientId: string
    readonly startDate: Date
    readonly endDate: Date
}

/**
 * IHearRateState
 * @property {IntradayHeartRate[]} data
 * @extends {IComponentState}
 */
export interface IHearRateState extends IComponentState {
    readonly data: IntradayHeartRate[]
}

/**
 * ITimeSeriesState
 * @property {TimeSeries} data
 * @extends {IComponentState}
 */
export interface ITimeSeriesState extends IComponentState {
    readonly data: TimeSeries
}

export interface INewReportRequest {
    readonly comorbidity: string,
    readonly polyPharmacy: string,
    readonly medicines: string,
    readonly depression: string,
    readonly sleepiness: string,
    readonly sarcopenia: string,
    readonly nocturiaPresence: string,
    readonly frailtySyndrome: string,
    readonly muscleStrength: string,
    readonly startDate: Date | undefined,
    readonly endDate: Date | undefined
}

/**
 * ISleepState
 * @property {Sleep[]} data
 * @extends {IComponentState}
 */
export interface ISleepState extends IComponentState {
    readonly data: Sleep[]
}

/**
 * IReportState
 * @property {Date} startDate
 * @property {Date} endDate
 * @property {ITimeSeriesState} steps
 * @property {ITimeSeriesState} activeMinutes
 * @property {IHearRateState} heartRate
 */

/**
 * Activities State
 */
export interface IListState extends IComponentState {
    readonly report: Report[]
    readonly paginator: IPaginator
}

/**
 * Report View Configuration
 */
interface IViewState {
    readonly bmi: boolean
    readonly waistCircumference: boolean
    readonly calfCircumference: boolean
    readonly handGrid: boolean
    readonly gaitSpeed: boolean
    readonly nocturia: boolean
    readonly depression: boolean
    readonly sleepiness: boolean
    readonly fragility: boolean
    readonly sarcopenia: boolean
    readonly muscleStrength: boolean
    readonly steps: boolean
    readonly activeMinutes: boolean
    readonly heartRate: boolean
    readonly sleep: boolean
    readonly signature: boolean
}

export interface IReportState extends INewReportRequest {
    readonly dialog: boolean
    readonly steps: ITimeSeriesState
    readonly activeMinutes: ITimeSeriesState
    readonly heartRate: IHearRateState
    readonly list: IListState
    readonly view: IViewState
}
