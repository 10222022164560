import { JsonUtils } from '../../../utils/json.util'
import { TimeSeriesInterval } from '../intraday/intraday.summary'

export class TimeSeriesFullFilter {
    private _start_date: string | undefined
    private _end_date: string | undefined
    private _start_time: string | undefined
    private _end_time: string | undefined
    private _interval: TimeSeriesInterval | undefined

    get start_date(): string | undefined {
        return this._start_date
    }

    set start_date(value: string | undefined) {
        value && value?.length > 10 ?
            this._start_date = value?.substr(0, 10)
            : this._start_date = value
    }

    get end_date(): string | undefined {
        return this._end_date
    }

    set end_date(value: string | undefined) {
        value && value?.length > 10 ?
            this._end_date = value?.substr(0, 10)
            : this._end_date = value
    }

    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        value && value?.length > 8 ?
            this._start_time = value?.substr(11, 8)
            : this._start_time = value
    }

    get end_time(): string | undefined {
        return this._end_time
    }

    set end_time(value: string | undefined) {
        value && value?.length > 8 ?
            this._end_time = value?.substr(11, 8)
            : this._end_time = value
    }

    get interval(): TimeSeriesInterval | undefined {
        return this._interval
    }

    set interval(value: TimeSeriesInterval | undefined) {
        this._interval = value
    }

    public fromJSON(json: any): TimeSeriesFullFilter {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_date !== undefined) {
            this.start_date = json.start_date
        }

        if (json.end_date !== undefined) {
            this.end_date = json.end_date
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.end_time !== undefined) {
            this.end_time = json.end_time
        }

        if (json.interval !== undefined) {
            this.interval = json.interval
        }

        return this
    }

    public toJSON(): any {
        return {
            start_date: this.start_date ? this.start_date : undefined,
            end_date: this.end_date ? this.end_date : undefined,
            start_time: this.start_time ? this.start_time : undefined,
            end_time: this.end_time ? this.end_time : undefined,
            interval: this.interval ? this.interval : undefined
        }
    }
}
