/**
 * Action types
 */

import { SnackBarMessageType } from '../../../components/snackbar'

export enum SnackBarTypes {
    OPEN = '@snack.bar/OPEN',
    CLOSE = '@snack.bar/CLOSE'
}

/**
 * State type
 */
export interface ISnackBarState {
    readonly open: boolean
    readonly message: string
    readonly title: string
    readonly type: SnackBarMessageType
}
