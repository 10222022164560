import { IAxiosResponse, IPaginator } from '../../store/ducks/root.types'
import Caregiver from '../../store/application/models/users/caregiver'
import axiosInstance from '../axios'
import { MAP_TYPE_USER_TO_ENDPOINT, TypesUser } from '../../store/application/models/users/users'
import Patient from '../../store/application/models/users/patient'
import userService from '../users'

class UserAssociatesService {

    constructor(private apiVersion: string = 'v1') {
    }

    public associatePatient(
        userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
        userId: string,
        patientId: string): Promise<void> {
        return axiosInstance
            .post(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}/patients/${patientId}`)
    }

    public disassociatePatient(
        userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
        userId: string, patientId: string): Promise<void> {
        return axiosInstance
            .delete(`${this.apiVersion}/${MAP_TYPE_USER_TO_ENDPOINT[userType]}/${userId}/patients/${patientId}`)
    }

    public getAllByHealthProfessionalId(
        healthProfessionalId: string,
        type: TypesUser.CAREGIVER | TypesUser.PATIENT,
        paginator?: IPaginator): Promise<IAxiosResponse<any[]>> {
        const params: URLSearchParams = userService.buildParams(paginator)
        const url = `${this.apiVersion}/healthprofessionals/${healthProfessionalId}/${MAP_TYPE_USER_TO_ENDPOINT[type]}`
        return axiosInstance
            .get(url, { params })
            .then((response) => {
                switch (type) {
                    case TypesUser.CAREGIVER:
                        return {
                            data: response.data.map((caregiver: any) => new Caregiver().fromJSON(caregiver)),
                            headers: response.headers
                        }
                    case TypesUser.PATIENT:
                        return {
                            data: response.data.map((patient: any) => new Patient().fromJSON(patient)),
                            headers: response.headers
                        }
                    default:
                        return response.data
                }
            })
    }

    public getAllByCaregiverId(caregiverId: string, paginator?: IPaginator): Promise<IAxiosResponse<Patient[]>> {
        const params: URLSearchParams = userService.buildParams(paginator)
        const url = `${this.apiVersion}/caregivers/${caregiverId}/patients`
        return axiosInstance
            .get(url, { params })
            .then((response) => {
                return {
                    data: response.data.map((patient: any) => new Patient().fromJSON(patient)),
                    headers: response.headers
                }
            })
    }
}

export default new UserAssociatesService()
