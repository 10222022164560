import { createTypes } from 'reduxsauce'
import Sleep from '../../../application/models/activity/sleep/sleep'
import { IComponentState, IPaginator } from '../../root.types'
import { IRemoveMeasurement } from '../types'
import { IHeartRateState } from '../activity/types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'

/**
 * Sleep types
 */
export const SleepTypes = createTypes(`
    RESET_LIST
    RESET_DETAILS
    
    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE
    
    LOAD_HEART_RATE_REQUEST
    LOAD_HEART_RATE_SUCCESS
    LOAD_HEART_RATE_FAILURE
    
    LOAD_SLEEP_REQUEST
    LOAD_SLEEP_SUCCESS
    LOAD_SLEEP_FAILURE
    CHANGE_SLEEP_PAGINATOR
    
    LOAD_MORE_SLEEP_REQUEST
    LOAD_MORE_SLEEP_SUCCESS
    LOAD_MORE_SLEEP_FAILURE
    
    CHANGE_DIALOG_REMOVE_SLEEP
    
    REMOVE_SLEEP_REQUEST
    REMOVE_SLEEP_SUCCESS
    REMOVE_SLEEP_FAILURE
    
    CHART_SLEEP_REQUEST
    CHART_SLEEP_SUCCESS
    CHART_SLEEP_FAILURE
    `,
    {
        prefix: '@patient.sleep/'
    }
)

/**
 * Action Types
 */
export interface IActionLoadSleep {
    readonly patientId: string
    readonly paginator?: IPaginator
    readonly filter?: TimeSeriesSimpleFilter
}

export interface IActionRemoveSleep {
    readonly patientId: string
    readonly ids: string[]
    readonly location: 'LIST' | 'DETAILS'
}

export interface IActionFindSleep {
    readonly patientId: string
    readonly sleepId: string
}

export interface IActionChartLoadSleep {
    readonly patientId: string
    readonly filter: TimeSeriesSimpleFilter
}

/**
 * Sleep State
 */
interface IListState extends IComponentState {
    readonly sleeps: Sleep[]
    readonly paginator: IPaginator
}

export interface IDetailsState extends IComponentState {
    readonly sleep: Sleep
    readonly heartRate: IHeartRateState
}

interface IChartState extends IComponentState {
    readonly sleeps: Sleep[]
}

/**
 * State type
 */
export interface ISleepState {
    readonly chart: IChartState
    readonly list: IListState
    readonly details: IDetailsState
    readonly remove: IRemoveMeasurement
}
