import { JsonUtils } from '../../../utils/json.util'

export default class HeartRateSummary {
    private _fat_burn_total: number | undefined
    private _cardio_total: number | undefined
    private _peak_total: number | undefined
    private _out_of_range_total: number | undefined

    get fat_burn_total(): number | undefined {
        return this._fat_burn_total
    }

    set fat_burn_total(value: number | undefined) {
        this._fat_burn_total = value
    }

    get cardio_total(): number | undefined {
        return this._cardio_total
    }

    set cardio_total(value: number | undefined) {
        this._cardio_total = value
    }

    get peak_total(): number | undefined {
        return this._peak_total
    }

    set peak_total(value: number | undefined) {
        this._peak_total = value
    }

    get out_of_range_total(): number | undefined {
        return this._out_of_range_total
    }

    set out_of_range_total(value: number | undefined) {
        this._out_of_range_total = value
    }

    public fromJSON(json: any): HeartRateSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.fat_burn !== undefined) {
            this.fat_burn_total = json.fat_burn
        }

        if (json.cardio !== undefined) {
            this.cardio_total = json.cardio
        }

        if (json.peak !== undefined) {
            this.peak_total = json.peak
        }

        if (json.out_of_range !== undefined) {
            this.out_of_range_total = json.out_of_range
        }

        return this
    }

    public toJSON(): any {
        return {
            fat_burn_total: this.fat_burn_total ? this.fat_burn_total : undefined,
            cardio_total: this.cardio_total ? this.cardio_total : undefined,
            peak_total: this.peak_total ? this.peak_total : undefined,
            out_of_range_total: this.out_of_range_total ? this.out_of_range_total : undefined
        }
    }
}
