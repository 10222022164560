import { Reducer } from 'redux'
import { AssociatesTypes, IAssociatesState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../../root.types'
import { TypesUser } from '../../../application/models/users/users'
import { IAvatarList } from '../types'

export const INITIAL_STATE: IAssociatesState = {
    caregivers: {
        users: [],
        avatars: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    patients: {
        users: [],
        avatars: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        },
        associating: [],
        loadingAssociation: false
    }
}

export const resetList = (state: IAssociatesState = INITIAL_STATE) => {
    return {
        ...state,
        caregivers: INITIAL_STATE.caregivers,
        patients: INITIAL_STATE.patients
    }
}

export const changePaginatorAssociates = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{
    userId: string,
    userType: TypesUser,
    paginator: IPaginator
}>) => {
    const { userType, paginator } = action.payload
    switch (userType) {
        case (TypesUser.CAREGIVER):
            return { ...state, caregivers: { ...state.caregivers, paginator, userType } }
        case TypesUser.PATIENT:
            return { ...state, patients: { ...state.patients, paginator, userType } }
        default:
            return state
    }
}

export const changeSearchPaginator = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{
    userType: TypesUser,
    search: ISearch
}>) => {
    const { userType, search } = action.payload
    switch (userType) {
        case (TypesUser.CAREGIVER):
            return {
                ...state,
                caregivers: { ...state.caregivers, paginator: { ...state.caregivers.paginator, search } }
            }
        case TypesUser.PATIENT:
            return {
                ...state,
                patients: { ...state.patients, paginator: { ...state.patients.paginator, search } }
            }
        default:
            return {
                ...state,
                patients: { ...state.patients, paginator: { ...state.patients.paginator, search } }
            }
    }
}

export const loadAssociatesRequest = (state: IAssociatesState = INITIAL_STATE, action: any) => {
    const { userType } = action.payload
    switch (userType) {
        case TypesUser.CAREGIVER:
            return { ...state, caregivers: request(state.caregivers) }
        case TypesUser.PATIENT:
            return { ...state, patients: request(state.patients) }
        default:
            return state
    }
}

export const loadAssociatesSuccess = (state: IAssociatesState = INITIAL_STATE, action: any) => {
    const { userType, users, headers } = action.payload
    switch (userType) {
        case TypesUser.CAREGIVER:
            return {
                ...state, caregivers: success({
                    ...state.caregivers,
                    users,
                    paginator: {
                        ...state.caregivers.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                })
            }
        case TypesUser.PATIENT:
            return {
                ...state, patients: success({
                    ...state.patients,
                    users,
                    paginator: {
                        ...state.patients.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                })
            }
        default:
            return state
    }
}

export const loadAssociatesFailure = (state: IAssociatesState = INITIAL_STATE, action: any) => {
    switch (action.payload.userType) {
        case TypesUser.CAREGIVER:
            return { ...state, caregivers: failure(state.caregivers) }
        case TypesUser.PATIENT:
            return { ...state, patients: failure(state.patients) }
        default:
            return state
    }
}

export const loadMoreAssociatesRequest = (state: IAssociatesState = INITIAL_STATE, action: any) => {
    const { userType, paginator } = action.payload
    switch (userType) {
        case TypesUser.CAREGIVER:
            return {
                ...state, caregivers: request({
                    ...state.caregivers,
                    paginator
                })
            }
        case TypesUser.PATIENT:
            return {
                ...state, patients: request({
                    ...state.patients,
                    paginator
                })
            }
        default:
            return state
    }
}

export const loadMoreAssociatesSuccess = (state: IAssociatesState = INITIAL_STATE, action: any) => {
    const { userType, users, headers } = action.payload
    switch (userType) {
        case TypesUser.CAREGIVER:
            const stateCaregivers = state.caregivers.users.concat(users)
            const avatarsCaregivers: IAvatarList[] = users.map(user => {
                return { userId: user.id, status: 'loading', img: '' }
            })
            const resultAvatarsCaregivers = state.caregivers.avatars.concat(avatarsCaregivers)
            return {
                ...state, caregivers: success({
                    ...state.caregivers,
                    users: stateCaregivers,
                    avatars: resultAvatarsCaregivers,
                    paginator: {
                        ...state.caregivers.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                })
            }

        case TypesUser.PATIENT:
            const statePatients = state.patients.users.concat(users)
            const avatars: IAvatarList[] = users.map(user => {
                return { userId: user.id, status: 'loading', img: '' }
            })
            const resultAvatarsPatients = state.patients.avatars.concat(avatars)
            return {
                ...state, patients: success({
                    ...state.patients,
                    users: statePatients,
                    avatars: resultAvatarsPatients,
                    paginator: {
                        ...state.patients.paginator,
                        totalRecords: parseInt(headers['x-total-count'], 10)
                    }
                })
            }
        default:
            return state
    }
}

export const loadMultipleAvatarSuccess = (state: IAssociatesState = INITIAL_STATE, action: IActionType) => {
    const { userId, avatar } = action.payload
    const filterCaregiver = state.caregivers.avatars.filter(element => element.userId !== userId)
    const filterPatient = state.patients.avatars.filter(element => element.userId !== userId)
    return {
        ...state,
        caregivers: {
            ...state.caregivers,
            avatars: filterCaregiver.concat({ status: 'available', userId, img: avatar })
        },
        patients: {
            ...state.patients,
            avatars: filterPatient.concat({ status: 'available', userId, img: avatar })
        }
    }
}

export const loadMultipleAvatarFailure = (state: IAssociatesState = INITIAL_STATE, action: IActionType) => {
    const { userId } = action.payload
    const filterCaregiver = state.caregivers.avatars.filter(element => element.userId !== userId)
    const filterPatient = state.patients.avatars.filter(element => element.userId !== userId)
    return {
        ...state,
        caregivers: {
            ...state.caregivers,
            avatars: filterCaregiver.concat({ status: '404', userId, img: '' })
        },
        patients: {
            ...state.patients,
            avatars: filterPatient.concat({ status: '404', userId, img: '' })
        }
    }
}

export const changePaginator = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{
    caregiverId: string
    paginator: IPaginator
}>) => {
    const { paginator } = action.payload
    return { ...state, patients: { ...state.patients, paginator } }
}

export const associationRequest = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{ patientId: string }>) => {
    const { patientId } = action.payload
    const listAssociating = state.patients.associating.concat(patientId)
    return { ...state, patients: { ...state.patients, loadingAssociation: true, associating: listAssociating } }
}

export const associationSuccess = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{ patientId: string }>) => {
    const { patientId } = action.payload
    const listAssociating = state.patients.associating.filter(element => element !== patientId)
    return {
        ...state,
        patients: {
            ...state.patients,
            loadingAssociation: false,
            error: false,
            success: true,
            associating: listAssociating
        }
    }
}

export const associationFailure = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{ patientId: string }>) => {
    const { patientId } = action.payload
    const listAssociating = state.patients.associating.filter(element => element !== patientId)
    return {
        ...state,
        patients: {
            ...state.patients,
            loadingAssociation: false,
            error: true,
            success: false,
            associating: listAssociating
        }
    }
}

export const removeSuccess = (state: IAssociatesState = INITIAL_STATE, action: IActionType<{ userId: string }>) => {
    const { userId } = action.payload
    const usersCaregiver = state.caregivers.users?.filter(user => user?.id !== userId)
    const usersPatients = state.patients.users?.filter(user => user?.id !== userId)
    return {
        ...state,
        caregivers: {
            ...state.caregivers,
            users:usersCaregiver
        },
        patients: {
            ...state.patients,
            users:usersPatients
        }
    }
}


const reducer: Reducer<IAssociatesState> = createReducer<IAssociatesState>(INITIAL_STATE, {
    [AssociatesTypes.RESET_LIST]: resetList,

    [AssociatesTypes.CHANGE_PAGINATOR_ASSOCIATES]: changePaginatorAssociates,
    [AssociatesTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,

    [AssociatesTypes.LOAD_ASSOCIATES_REQUEST]: loadAssociatesRequest,
    [AssociatesTypes.LOAD_ASSOCIATES_SUCCESS]: loadAssociatesSuccess,
    [AssociatesTypes.LOAD_ASSOCIATES_FAILURE]: loadAssociatesFailure,

    [AssociatesTypes.LOAD_MORE_ASSOCIATES_REQUEST]: loadMoreAssociatesRequest,
    [AssociatesTypes.LOAD_MORE_ASSOCIATES_SUCCESS]: loadMoreAssociatesSuccess,
    [AssociatesTypes.LOAD_MORE_ASSOCIATES_FAILURE]: loadAssociatesFailure,

    [AssociatesTypes.LOAD_MULTIPLE_AVATAR_SUCCESS]: loadMultipleAvatarSuccess,
    [AssociatesTypes.LOAD_MULTIPLE_AVATAR_FAILURE]: loadMultipleAvatarFailure,


    [AssociatesTypes.LOAD_BY_CAREGIVER_ID_REQUEST]: loadAssociatesRequest,
    [AssociatesTypes.LOAD_BY_CAREGIVER_ID_SUCCESS]: loadAssociatesSuccess,
    [AssociatesTypes.LOAD_BY_CAREGIVER_ID_FAILURE]: loadAssociatesFailure,

    [AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_REQUEST]: loadMoreAssociatesRequest,
    [AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_SUCCESS]: loadMoreAssociatesSuccess,
    [AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_FAILURE]: loadAssociatesFailure,

    [AssociatesTypes.CHANGE_PAGINATOR]: changePaginator,

    [AssociatesTypes.LOAD_MULTIPLE_AVATAR_SUCCESS]: loadMultipleAvatarSuccess,
    [AssociatesTypes.LOAD_MULTIPLE_AVATAR_FAILURE]: loadMultipleAvatarFailure,

    [AssociatesTypes.ASSOCIATE_PATIENT_REQUEST]: associationRequest,
    [AssociatesTypes.ASSOCIATE_PATIENT_SUCCESS]: associationSuccess,
    [AssociatesTypes.ASSOCIATE_PATIENT_FAILURE]: associationFailure,

    [AssociatesTypes.DISASSOCIATE_PATIENT_REQUEST]: associationRequest,
    [AssociatesTypes.DISASSOCIATE_PATIENT_SUCCESS]: associationSuccess,
    [AssociatesTypes.DISASSOCIATE_PATIENT_FAILURE]: associationFailure,

    [AssociatesTypes.REMOVE_ASSOCIATED_SUCCESS]: removeSuccess,


})

export default reducer
