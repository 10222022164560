import { JsonUtils } from '../../../utils/json.util'

export default class IntradayDataSetItem {
    private _time: string | undefined
    private _value: number | undefined

    get time(): string | undefined {
        return this._time
    }

    set time(value: string | undefined) {
        this._time = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    public fromJSON(json: any): IntradayDataSetItem {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.time !== undefined) {
            this.time = json.time
        }

        if (json.value !== undefined) {
            this.value = json.value
        }

        return this
    }

    public toJSON(): any {
        return {
            time: this.time ? this.time : undefined,
            value: this.value ? this.value : undefined
        }
    }
}
