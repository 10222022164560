import Measurement, { BodySide } from "./measurement"
import { TypeMeasurement } from "./measurements.last"

export default class CalfCircumference extends Measurement {

    private _value: number | undefined
    private _leg: BodySide | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.CALF_CIRCUMFERENCE
        this._leg = '' as BodySide
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get leg(): BodySide | undefined {
        return this._leg
    }

    set leg(value: BodySide | undefined) {
        this._leg = value
    }

    public fromJSON(json: any): CalfCircumference {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (this.leg !== undefined) {
            this.leg = json.leg
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined,
            leg: this.leg ? this.leg : undefined
        }
    }
}
