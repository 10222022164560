import { JsonUtils } from '../../../utils/json.util'
import SleepSummaryData from './summary.data'

export default class SleepPhasesSummary {
    private _awake: SleepSummaryData | undefined
    private _asleep: SleepSummaryData | undefined
    private _restless: SleepSummaryData | undefined

    get awake(): SleepSummaryData | undefined {
        return this._awake
    }

    set awake(value: SleepSummaryData | undefined) {
        this._awake = value
    }

    get asleep(): SleepSummaryData | undefined {
        return this._asleep
    }

    set asleep(value: SleepSummaryData | undefined) {
        this._asleep = value
    }

    get restless(): SleepSummaryData | undefined {
        return this._restless
    }

    set restless(value: SleepSummaryData | undefined) {
        this._restless = value
    }

    public fromJSON(json: any): SleepPhasesSummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.awake !== undefined) {
            this.awake = new SleepSummaryData().fromJSON(json.awake)
        }

        if (json.asleep !== undefined) {
            this.asleep = new SleepSummaryData().fromJSON(json.asleep)
        }

        if (json.restless !== undefined) {
            this.restless = new SleepSummaryData().fromJSON(json.restless)
        }

        return this
    }

    public toJSON(): any {
        return {
            awake: this.awake ? this.awake.toJSON() : undefined,
            asleep: this.asleep ? this.asleep.toJSON() : undefined,
            restless: this.restless ? this.restless.toJSON() : undefined
        }
    }
}
