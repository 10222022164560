import Measurement, { BodySide } from './measurement'
import { TypeMeasurement } from './measurements.last'

export default class HandGrip extends Measurement {

    private _value: number | undefined
    private _hand: BodySide | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.HAND_GRIP
        this._hand = '' as BodySide
    }

    get value(): number | undefined {
        return parseFloat(`${this._value?.toFixed(1)}`)
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get hand(): BodySide | undefined {
        return this._hand
    }

    set hand(value: BodySide | undefined) {
        this._hand = value
    }

    public fromJSON(json: any): HandGrip {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = Number(json.value)
        }

        if (json.hand !== undefined) {
            this.hand = json.hand
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined,
            hand: this.hand ? this.hand : undefined
        }
    }
}
