import { JsonUtils } from '../../../utils/json.util'

export enum PhysicalActivityLevels {
    SEDENTARY = 'sedentary',
    LIGHTLY = 'lightly',
    FAIRLY = 'fairly',
    VERY = 'very'
}

export default class ActivityLevel{
    private _name: PhysicalActivityLevels | undefined
    private _duration: number | undefined

    get name(): PhysicalActivityLevels | undefined {
        return this._name
    }

    set name(value: PhysicalActivityLevels | undefined) {
        this._name = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    public fromJSON(json: any): ActivityLevel {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        return this
    }

    public toJSON(): any {
        return {
            name: this.name ? this.name : undefined,
            duration: this.duration ? this.duration : undefined
        }
    }
}
