import Activity from '../activity'
import ActivityLevel from './level'
import HeartRateZone from '../../time.series/heart.rate/heart.rate.zone'

export default class PhysicalActivity extends Activity {
    private _name: string | undefined
    private _calories: number | undefined
    private _steps: number | undefined
    private _distance: number | undefined
    private _levels: ActivityLevel[] | undefined
    private _heart_rate_average: number | undefined
    private _heart_rate_zones: HeartRateZone | undefined

    get name(): string | undefined {
        return this._name
    }

    set name(value: string | undefined) {
        this._name = value
    }

    get calories(): number | undefined {
        return this._calories
    }

    set calories(value: number | undefined) {
        this._calories = value
    }

    get steps(): number | undefined {
        return this._steps
    }

    set steps(value: number | undefined) {
        this._steps = value
    }

    get distance(): number | undefined {
        return this._distance
    }

    set distance(value: number | undefined) {
        this._distance = value
    }

    get levels(): ActivityLevel[] | undefined {
        return this._levels
    }

    set levels(value: ActivityLevel[] | undefined) {
        this._levels = value
    }

    get heart_rate_average(): number | undefined {
        return this._heart_rate_average
    }

    set heart_rate_average(value: number | undefined) {
        this._heart_rate_average = value
    }

    get heart_rate_zones(): HeartRateZone | undefined {
        return this._heart_rate_zones
    }

    set heart_rate_zones(value: HeartRateZone | undefined) {
        this._heart_rate_zones = value
    }

    public fromJSON(json: any): PhysicalActivity {
        super.fromJSON(json)

        if (json.name !== undefined) {
            this.name = json.name
        }

        if (json.calories !== undefined) {
            this.calories = json.calories
        }

        if (json.steps !== undefined) {
            this.steps = json.steps
        }

        if (json.distance !== undefined) {
            this.distance = json.distance
        }

        if (json.levels !== undefined) {
            this.levels = json.levels.map(level => new ActivityLevel().fromJSON(level))
        }

        if (json.heart_rate_average !== undefined) {
            this.heart_rate_average = json.heart_rate_average
        }

        if (json.heart_rate_zones !== undefined) {
            this.heart_rate_zones = new HeartRateZone().fromJSON(json.heart_rate_zones)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            name: this.name ? this.name : undefined,
            calories: this.calories ? this.calories : undefined,
            steps: this.steps ? this.steps : undefined,
            distance: this.distance ? this.distance : undefined,
            levels: this.levels ? this.levels.map((item: ActivityLevel) => item.toJSON()) : undefined,
            heart_rate_average: this.heart_rate_average ? this.heart_rate_average : undefined,
            heart_rate_zones: this.heart_rate_zones ? this.heart_rate_zones.toJSON() : undefined
        }
    }
}
