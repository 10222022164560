import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import LastMeasurements, { TypeMeasurement } from '../store/application/models/measurements/measurements.last'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'
import { FactoryMeasurement } from '../store/application/utils/factory.measurement'
import Measurement from '../store/application/models/measurements/measurement'
import { AxiosResponse } from 'axios'

class MeasurementsService {

    constructor(private apiVersion: string = 'v1') {
    }

    public addMeasurement(measure: any): Promise<any> {
        return axiosInstance
            .post(`${this.apiVersion}/patients/${measure?.patient_id}/measurements`, measure?.toJSON())
            .then((response: AxiosResponse<any>) => FactoryMeasurement.build(response.data, measure.type))
    }

    public getLast(patientId: string): Promise<LastMeasurements> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/measurements/last`)
            .then((response: IAxiosResponse<LastMeasurements>) => new LastMeasurements().fromJSON(response.data))
    }

    public getAll(
        patientId: string,
        type: TypeMeasurement | string,
        paginator?: IPaginator,
        filter?: TimeSeriesSimpleFilter): Promise<IAxiosResponse<Measurement[]>> {
        const params = this.buildParams(type, paginator, filter)
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/measurements`, { params })
            .then((response: IAxiosResponse<any[]>) => {
                return {
                    data: response
                        ?.data
                        ?.map(measurement => FactoryMeasurement.build(measurement, measurement?.type || type)),
                    headers: response.headers
                }
            })
    }

    public remove(patientId: string, measurementId: string): Promise<any> {
        return axiosInstance
            .delete(`${this.apiVersion}/patients/${patientId}/measurements/${measurementId}`)
    }


    private buildParams(
        type: TypeMeasurement | string,
        paginator?: IPaginator,
        filter?: TimeSeriesSimpleFilter): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
                || (paginator?.search?.key === 'email' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        if (filter?.start_date) {
            const endDate = filter?.end_date ? filter?.end_date : filter?.start_date
            params.append('timestamp', `gte:${filter?.start_date}T00:00:00.000Z`)
            params.append('timestamp', `lt:${endDate}T23:59:59.999Z`)
        }
        if (type) {
            params.append('type', `${type}`)
        }
        params.append('sort', '-timestamp')
        return params
    }

}

export default new MeasurementsService()
