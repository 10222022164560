import { JsonUtils } from '../../utils/json.util'

export default class DeviceCode {
    private _code: number | undefined
    private _exp: number | undefined

    get code(): number | undefined {
        return this._code
    }

    set code(value: number | undefined) {
        this._code = value
    }

    get exp(): number | undefined {
        return this._exp
    }

    set exp(value: number | undefined) {
        this._exp = value
    }

    public fromJSON(json: any): DeviceCode {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.code !== undefined) {
            this.code = json.code
        }

        if (json.exp !== undefined) {
            this.exp = json.exp
        }

        return this
    }

    public toJSON(): any {
        return {
            code: this.code ? this.code : undefined,
            exp: this.exp ? this.exp : undefined
        }
    }


}
