import { JsonUtils } from '../../utils/json.util'

export default class DataSetItem {
    private _date: string | undefined
    private _value: number | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    public fromJSON(json: any): DataSetItem {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.value !== undefined) {
            this.value = json.value
        }

        return this
    }

    public toJSON(): any {
        return {
            date: this.date ? this.date : undefined,
            value: this.value ? this.value : undefined
        }
    }
}
