import { JsonUtils } from '../../utils/json.util'
import { NoteType } from '../../utils/note.type'

export default abstract class Note {
    protected _type: NoteType | undefined
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined
    private _date: string | undefined
    private _text: string | undefined
    private _patient_id: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value?.substr(0, 10)
    }

    get text(): string | undefined {
        return this._text
    }

    set text(value: string | undefined) {
        this._text = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get type(): NoteType | undefined {
        return this._type
    }

    public fromJSON(json: any): Note {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.text !== undefined) {
            this.text = json.text
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            date: this.date ? this.date : undefined,
            text: this.text ? this.text : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined
        }
    }

}
