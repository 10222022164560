import { all, apply, call, put, takeLatest } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import authService from '../../../services/auth'
import userService from '../../../services/users'
import { AxiosResponse } from 'axios'
import { changePasswordFailure, changePasswordSuccess, forgotFailure, forgotSuccess, loginFailure, loginSuccess } from './actions'
import { IActionType } from '../root.types'
import { AuthTypes, IAuth, IChangePassword, IForgot } from './types'
import { open } from '../snack.bar/actions'
import { SnackBarMessageType } from '../../../components/snackbar'
import { TypesUser } from '../../application/models/users/users'
import { changeAvatar, changeLanguage, changeUsername } from '../layout/actions'
import { AccessToken } from '../../application/models/auth/access.token'
import avatarService from '../../../services/users/avatar'
import { LanguageOptions } from '../../../i18n'
import { getUserLoggedSuccess } from '../user/actions'

export function* authenticate(action: IActionType<AxiosResponse<IAuth>>) {
    try {
        const { data } = action.payload
        const accessToken: AccessToken = yield apply(authService, authService.login, [data])
        if (!accessToken?.sub_type || !Object.values(TypesUser).includes(accessToken.sub_type)) {
            yield put(
                open(
                    SnackBarMessageType.ERROR,
                    'SNACKBAR.HANDLER_ERROR.TITLE.PROTECTED_USER',
                    'SNACKBAR.HANDLER_ERROR.MESSAGE.USER_TYPE_NOT_ALLOWED'
                )
            )
            yield apply(authService, authService.logout, [])
            throw new Error('User type not allowed!')
        }
        yield put(loginSuccess())
        yield put(push(`/app/home`))
        yield call(getUserLogged, accessToken.sub, accessToken.sub_type)
    } catch (err) {
        yield put(loginFailure())
    }
}

export function* forgot(action: IActionType<AxiosResponse<IForgot>>) {
    try {
        const { data } = action.payload
        yield apply(authService, authService.forgot, [data])
        yield put(forgotSuccess())
    } catch (err) {
        yield put(forgotFailure())
    }
}

export function* changePassword(action: IActionType<AxiosResponse<IChangePassword>>) {
    try {
        const { data } = action.payload
        yield apply(authService, authService.changePassword, [data])
        yield put(changePasswordSuccess())
        yield put(open(SnackBarMessageType.SUCCESS, 'AUTH.CHANGE_PASSWORD.SNACKBAR.TITLE', 'AUTH.CHANGE_PASSWORD.SNACKBAR.MESSAGE'))
    } catch (err) {
        yield put(changePasswordFailure())
    }
}

export function* getUserLogged(userId: string | undefined, type: TypesUser | undefined) {
    if (userId && type) {
        try {
            const user: any = yield apply(userService, userService.getById, [userId, type])
            yield put(getUserLoggedSuccess(user))
            yield put(changeLanguage(user.language))
            yield put(changeUsername(user.name))
        } catch (e) {
            yield put(changeLanguage(LanguageOptions.PT_BR))
            yield put(changeUsername('...'))
        }
        try {
            const avatar: any = yield apply(avatarService, avatarService.get, [userId, true])
            yield put(changeAvatar(avatar))
        } catch (e) {
            yield put(changeAvatar(''))
        }
    }
}

export default function* authSaga() {
    return yield all([
        takeLatest(AuthTypes.LOGIN_REQUEST, authenticate),
        takeLatest(AuthTypes.FORGOT_REQUEST, forgot),
        takeLatest(AuthTypes.CHANGE_PASSWORD_REQUEST, changePassword)
    ])
}
