import { createTypes } from 'reduxsauce'
import { IPaginator } from '../../root.types'
import { TypesUser } from '../../../application/models/users/users'
import { IListState } from '../types'

export const AssociatesTypes = createTypes(`

    RESET_LIST

    CHANGE_PAGINATOR_ASSOCIATES
    CHANGE_SEARCH_PAGINATOR
    CHANGE_REMOVE_DIALOG
    REMOVE_ASSOCIATED_REQUEST
    REMOVE_ASSOCIATED_SUCCESS

    LOAD_AVATAR_REQUEST
    LOAD_AVATAR_SUCCESS
    LOAD_AVATAR_FAILURE
    LOAD_MULTIPLE_AVATAR_SUCCESS
    LOAD_MULTIPLE_AVATAR_FAILURE

    LOAD_ASSOCIATES_REQUEST
    LOAD_ASSOCIATES_SUCCESS
    LOAD_ASSOCIATES_FAILURE

    LOAD_MORE_ASSOCIATES_REQUEST
    LOAD_MORE_ASSOCIATES_SUCCESS
    LOAD_MORE_ASSOCIATES_FAILURE
    
    LOAD_BY_HEALTH_PROFESSIONAL_ID
    LOAD_MORE_BY_HEALTH_PROFESSIONAL_ID
    
    LOAD_BY_CAREGIVER_ID_REQUEST
    LOAD_BY_CAREGIVER_ID_SUCCESS
    LOAD_BY_CAREGIVER_ID_FAILURE
    
    LOAD_MORE_BY_CAREGIVER_ID_REQUEST
    LOAD_MORE_BY_CAREGIVER_ID_SUCCESS
    LOAD_MORE_BY_CAREGIVER_ID_FAILURE
    
    LOAD_MULTIPLE_AVATAR_SUCCESS
    LOAD_MULTIPLE_AVATAR_FAILURE
    
    CHANGE_PAGINATOR 
    CHANGE_SEARCH_PAGINATOR
    
    REMOVE_REQUEST
    REMOVE_SUCCESS
    
    ASSOCIATE_PATIENT_REQUEST
    ASSOCIATE_PATIENT_SUCCESS
    ASSOCIATE_PATIENT_FAILURE
    
    DISASSOCIATE_PATIENT_REQUEST
    DISASSOCIATE_PATIENT_SUCCESS
    DISASSOCIATE_PATIENT_FAILURE
`,
    {
        prefix: '@associates/'
    }
)

/**
 * Action types
 */

export interface IActionLoad {
    readonly userId: string
    readonly userType: TypesUser.CAREGIVER | TypesUser.PATIENT
    readonly paginator: IPaginator
}


/**
 * State types
 */
interface IPatientState extends IListState {
    readonly loadingAssociation: boolean
    readonly associating: string[]
}

export interface IAssociatesState {
    readonly patients: IPatientState
    readonly caregivers: IListState
}
