import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Diagnosis from '../store/application/models/diagnosis/diagnosis'
import { TimeSeriesSimpleFilter } from '../store/application/models/time.series/filters/simple.filter'
import Note from '../store/application/models/note/note'
import { NoteType } from '../store/application/utils/note.type'
import { FactoryNote } from '../store/application/utils/factory.note'

const MAP_NOTE_TYPE_ENDPOINT = {
    [NoteType.DIAGNOSTIC]: 'diagnoses',
    [NoteType.EVOLUTION]: 'evolutions'
}

class NoteService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(note: Note): Promise<Note> {
        const type: NoteType = NoteType[`${note.type}`]
        return axiosInstance
            .post(`${this.apiVersion}/patients/${note.patient_id}/${MAP_NOTE_TYPE_ENDPOINT[type]}`, note.toJSON())
            .then(response => FactoryNote.build(response.data, type))
    }

    public getAll(
        patientId: string,
        type: NoteType,
        paginator?: IPaginator,
        filter?: TimeSeriesSimpleFilter
    ): Promise<IAxiosResponse<Note[]>> {
        const params: URLSearchParams = this.buildParams(paginator, filter)
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/${MAP_NOTE_TYPE_ENDPOINT[type]}`, { params })
            .then((response) => {
                return {
                    data: response.data.map((data: any) => FactoryNote.build(data, type)),
                    headers: response.headers
                }
            })
    }

    public getById(patientId: string, type: NoteType, noteId: string): Promise<Diagnosis> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/${MAP_NOTE_TYPE_ENDPOINT[type]}/${noteId}`)
            .then(response => FactoryNote.build(response.data, type))
    }

    public update(note: Note): Promise<Diagnosis> {
        const type: NoteType = NoteType[`${note.type}`]
        return axiosInstance
            .patch(
                `${this.apiVersion}/patients/${note.patient_id}/${MAP_NOTE_TYPE_ENDPOINT[type]}/${note.id}`,
                note.toJSON()
            )
            .then(response => FactoryNote.build(response.data, type))
    }

    public remove(patientId: string, type: NoteType, noteId: string) {
        return axiosInstance
            .delete(`${this.apiVersion}/patients/${patientId}/${MAP_NOTE_TYPE_ENDPOINT[type]}/${noteId}`)
    }

    public buildParams(paginator?: IPaginator, filter?: TimeSeriesSimpleFilter): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch = (paginator?.search?.key === 'name' && paginator?.search?.value)
            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }

        if (filter?.start_date) {
            const endDate = filter?.end_date ? filter?.end_date : filter?.start_date
            params.append('date', `gte:${filter?.start_date}`)
            params.append('date', `lte:${endDate}`)
        }

        return params
    }
}

export default new NoteService()
