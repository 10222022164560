import User, { TypesUser } from './users'

export default class Admin extends User {
    // Readonly
    private _total_admins: number | undefined
    private _total_health_professionals: number | undefined
    private _total_caregivers: number | undefined
    private _total_patients: number | undefined

    constructor() {
        super()
        this.type = TypesUser.ADMIN
    }

    get total_admins(): number | undefined {
        return this._total_admins
    }

    set total_admins(value: number | undefined) {
        this._total_admins = value
    }

    get total_health_professionals(): number | undefined {
        return this._total_health_professionals
    }

    set total_health_professionals(value: number | undefined) {
        this._total_health_professionals = value
    }

    get total_caregivers(): number | undefined {
        return this._total_caregivers
    }

    set total_caregivers(value: number | undefined) {
        this._total_caregivers = value
    }

    get total_patients(): number | undefined {
        return this._total_patients
    }

    set total_patients(value: number | undefined) {
        this._total_patients = value
    }

    public fromJSON(json: any): Admin {
        super.fromJSON(json)

        if (json.email !== undefined) {
            this.email = json.email
        }

        if (json.total_admins !== undefined) {
            this.total_admins = json.total_admins
        }

        if (json.total_health_professionals !== undefined) {
            this.total_health_professionals = json.total_health_professionals
        }

        if (json.total_caregivers !== undefined) {
            this.total_caregivers = json.total_caregivers
        }

        if (json.total_patients !== undefined) {
            this.total_patients = json.total_patients
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            total_admins: this.total_admins ? this.total_admins : undefined,
            total_health_professionals: this.total_health_professionals ? this.total_health_professionals : undefined,
            total_caregivers: this.total_caregivers ? this.total_caregivers : undefined,
            total_patients: this.total_patients ? this.total_patients : undefined
        }
    }
}
