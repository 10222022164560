import { JsonUtils } from '../../../utils/json.util'
import HeartRateZone from './heart.rate.zone'

export default class HeartRateItem {
    private _date: string | undefined
    private _value: number | undefined
    private _zones: HeartRateZone | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get zones(): HeartRateZone | undefined {
        return this._zones
    }

    set zones(value: HeartRateZone | undefined) {
        this._zones = value
    }

    public fromJSON(json: any): HeartRateItem {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (json.zones !== undefined) {
            this.zones = new HeartRateZone().fromJSON(json.zones)
        }
        return this
    }

    public toJSON(): any {
        return {
            date: this.date ? this.date : undefined,
            value: this.value ? this.value : undefined,
            zones: this.zones ? this.zones.toJSON() : undefined
        }
    }
}
