import { createTypes } from 'reduxsauce'
import Goal from '../../../application/models/users/goals'

export const PatientTypes = createTypes(`
    SAVE_GOALS_REQUEST
    SAVE_GOALS_SUCCESS
    SAVE_GOALS_FAILURE
`,
    {
        prefix: '@patient/'
    }
)

/**
 * Action types
 */
export interface IActionSaveGoals {
    readonly patientId: string
    readonly goals: Goal
}
