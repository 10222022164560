import 'moment/locale/pt-br'
import moment from 'moment'
import i18next from 'i18next'

/*
moment.locale();         // en
moment().format('LT');   // 10:47 AM
moment().format('LTS');  // 10:47:49 AM
moment().format('L');    // 01/09/2021
moment().format('l');    // 1/9/2021
moment().format('LL');   // January 9, 2021
moment().format('ll');   // Jan 9, 2021
moment().format('LLL');  // January 9, 2021 10:47 AM
moment().format('lll');  // Jan 9, 2021 10:47 AM
moment().format('LLLL'); // Saturday, January 9, 2021 10:47 AM
moment().format('llll');  // Sat, Jan 9, 2021 10:48 AM
*/

class UtilDatePicker {

    /**
     * Select format date and language
     * @param date, select date
     * @param format, select format date
     */
    public static formatDate(date: any, format: string): string {
        if (!date) {
            return ''
        }
        return moment(date).locale(i18next.language).format(format)
    }

    /**
     * Transforms string from dd/mm/yyyy format to yyyy-mm-dd
     * @param date, Date to be formatted
     */
    public static usaFormatDate(date: any | undefined | null): string {
        const regex = new RegExp(/^([0-9]{4})[-]([0-9]{2})[-]([0-9]{2})$/)
        if (regex.test(date)) {
            return date
        }
        return date ? this.formatDates(date, '/', '-') : ''
    }

    /**
     * Transforms string from yyyy-mm-dd format to dd/mm/yyyy
     * @param date, Date to be formatted
     */
    public static brFormatDate(date: any | undefined | null): string {
        const regex = new RegExp(/^([0-9]{2})[/]([0-9]{2})[/]([0-9]{4})$/)
        if (regex.test(date)) {
            return date
        }
        return date ? this.formatDates(date, '-', '/') : ''
    }

    private static formatDates(date: Date, from: string, to: string): string {
        if (!date) {
            return date
        }
        const temp = date.toLocaleDateString('pt-br').split(from)
        return `${temp[2]}${to}${temp[1]}${to}${temp[0]}`
    }

    /* Formats the date according to the from and to parameters */
    private static formatMonth(date: string, from: string, to: string): string {
        if (!date) {
            return date
        }
        const temp = date.split(from)
        return `${temp[1]}${to}${temp[0]}`
    }
}

export default UtilDatePicker
