import Measurement from './measurement'
import { TypeMeasurement } from './measurements.last'

export default class ManualGaitSpeed extends Measurement {
    private _value: number | undefined
    private _time_in_seconds: number | undefined
    private _distance_in_meters: number | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.MANUAL_GAIT_SPEED
    }

    get value(): number | undefined {
        return parseFloat(`${this._value?.toFixed(2)}`)
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get time_in_seconds(): number | undefined {
        return parseFloat(`${this._time_in_seconds?.toFixed(2)}`)
    }

    set time_in_seconds(value: number | undefined) {
        this._time_in_seconds = value
    }

    get distance_in_meters(): number | undefined {
        return this._distance_in_meters
    }

    set distance_in_meters(value: number | undefined) {
        this._distance_in_meters = value
    }

    public fromJSON(json: any): ManualGaitSpeed {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = Number(json.value)
        }

        if (json.time_in_seconds !== undefined) {
            this.time_in_seconds = Number(json.time_in_seconds)
        }

        if (json.distance_in_meters !== undefined) {
            this.distance_in_meters = Number(json.distance_in_meters)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined,
            time_in_seconds: this.time_in_seconds ? this.time_in_seconds : undefined,
            distance_in_meters: this.distance_in_meters ? this.distance_in_meters : undefined,
            unit: undefined
        }
    }
}
