import Address from './address'
import User, { TypesUser } from './users'

export default class HealthProfessional extends User {
    // Required
    private _council_number: string | undefined
    private _health_area: string | undefined
    private _address: Address | undefined
    // Readonly
    private _total_patients: number | undefined
    private _total_caregivers: number | undefined

    constructor() {
        super()
        this.type = TypesUser.HEALTH_PROFESSIONAL
    }

    get council_number(): string | undefined {
        return this._council_number
    }

    set council_number(value: string | undefined) {
        this._council_number = value
    }

    get health_area(): string | undefined {
        return this._health_area
    }

    set health_area(value: string | undefined) {
        this._health_area = value
    }

    get address(): Address | undefined {
        return this._address
    }

    set address(value: Address | undefined) {
        this._address = value
    }

    get total_patients(): number | undefined {
        return this._total_patients
    }

    set total_patients(value: number | undefined) {
        this._total_patients = value
    }

    get total_caregivers(): number | undefined {
        return this._total_caregivers
    }

    set total_caregivers(value: number | undefined) {
        this._total_caregivers = value
    }

    public fromJSON(json: any): HealthProfessional {
        super.fromJSON(json)

        if (json.council_number !== undefined) {
            this.council_number = json.council_number
        }

        if (json.health_area !== undefined) {
            this.health_area = json.health_area
        }

        if (json.address !== undefined) {
            this.address = new Address().fromJSON(json.address)
        }

        if (json.total_patients !== undefined) {
            this.total_patients = json.total_patients
        }

        if (json.total_caregivers !== undefined) {
            this.total_caregivers = json.total_caregivers
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            council_number: this.council_number ? this.council_number : undefined,
            health_area: this.health_area ? this.health_area : undefined,
            address: this.address ? this.address.toJSON() : undefined,
            total_patients: this.total_patients ? this.total_patients : undefined,
            total_caregivers: this.total_caregivers ? this.total_caregivers : undefined
        }
    }
}