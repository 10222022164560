import { Reducer } from 'redux'
import { INewReportRequest, IReportState, ReportTypes } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, request, success } from '../../root.types'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import TimeSeries from '../../../application/models/time.series/time.series'

export const INITIAL_STATE: IReportState = {
    dialog: false,
    comorbidity: '',
    polyPharmacy: '',
    depression: '',
    frailtySyndrome: '',
    medicines: '',
    muscleStrength: '',
    nocturiaPresence: '',
    sarcopenia: '',
    sleepiness: '',
    startDate: undefined,
    endDate: undefined,
    steps: {
        data: new TimeSeries(),
        loading: false,
        error: false,
        success: false
    },
    activeMinutes: {
        data: new TimeSeries(),
        loading: false,
        error: false,
        success: false
    },
    heartRate: {
        data: [],
        loading: false,
        error: false,
        success: false
    },
    list: {
        report: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    view: {
        bmi: true,
        waistCircumference: true,
        calfCircumference: true,
        handGrid: true,
        gaitSpeed: true,
        nocturia: true,
        depression: true,
        sleepiness: true,
        fragility: true,
        sarcopenia: true,
        muscleStrength: true,
        steps: true,
        activeMinutes: true,
        heartRate: true,
        sleep: true,
        signature: true
    }
}

/* Steps reducers */
export const reset = (state: IReportState = INITIAL_STATE) => {
    return INITIAL_STATE
}

export const loadStepsRequest = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        steps: request(state.steps)
    }
}

export const loadStepSuccess = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<{ data: TimeSeries[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        steps: success({ ...state.steps, data })
    }
}

export const loadStepsFailure = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        steps: failure(state.steps)
    }
}


/* Steps reducers */

export const loadActiveMinutesRequest = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        activeMinutes: request(state.activeMinutes)
    }
}

export const loadActiveMinutesSuccess = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<{ data: TimeSeries[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        activeMinutes: success({ ...state.activeMinutes, data })
    }
}

export const loadActiveMinutesFailure = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        activeMinutes: failure(state.activeMinutes)
    }
}

/* Heart rate reducers */

export const loadHeartRateRequest = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        heartRate: request(state.heartRate)
    }
}

export const loadHearRateSuccess = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<{ data: IntradayHeartRate[] }>) => {
    const { data } = action.payload
    return {
        ...state,
        heartRate: success({ ...state.heartRate, data })
    }
}

export const loadHeartRateFailure = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        heartRate: failure(state.heartRate)
    }
}

export const changeDialog = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<{ dialog: boolean }>) => {
    const { dialog } = action.payload
    return {
        ...state,
        dialog
    }
}

export const newReportRequest = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<INewReportRequest>) => {
    const {
        medicines,
        comorbidity,
        polyPharmacy,
        depression,
        frailtySyndrome,
        muscleStrength,
        nocturiaPresence,
        sarcopenia,
        sleepiness,
        startDate,
        endDate
    } = action.payload
    return {
        ...state,
        medicines,
        comorbidity,
        polyPharmacy,
        depression,
        frailtySyndrome,
        muscleStrength,
        nocturiaPresence,
        sarcopenia,
        sleepiness,
        startDate,
        endDate
    }
}

export const changeReportView = (
    state: IReportState = INITIAL_STATE,
    action: IActionType<{ field: string, value: boolean }>) => {
    const { field, value } = action.payload
    return {
        ...state,
        view: {
            ...state.view,
            [field]: value
        }
    }
}

export const resetReportView = (state: IReportState = INITIAL_STATE) => {
    return {
        ...state,
        view: INITIAL_STATE.view
    }
}

const reducer: Reducer<IReportState> = createReducer<IReportState>(INITIAL_STATE, {
    [ReportTypes.RESET]: reset,

    [ReportTypes.LOAD_STEPS_REQUEST]: loadStepsRequest,
    [ReportTypes.LOAD_STEPS_SUCCESS]: loadStepSuccess,
    [ReportTypes.LOAD_STEPS_FAILURE]: loadStepsFailure,

    [ReportTypes.LOAD_ACTIVE_MINUTES_REQUEST]: loadActiveMinutesRequest,
    [ReportTypes.LOAD_ACTIVE_MINUTES_SUCCESS]: loadActiveMinutesSuccess,
    [ReportTypes.LOAD_ACTIVE_MINUTES_FAILURE]: loadActiveMinutesFailure,

    [ReportTypes.LOAD_HEART_RATE_REQUEST]: loadHeartRateRequest,
    [ReportTypes.LOAD_HEART_RATE_SUCCESS]: loadHearRateSuccess,
    [ReportTypes.LOAD_HEART_RATE_FAILURE]: loadHeartRateFailure,

    [ReportTypes.CHANGE_DIALOG]: changeDialog,
    [ReportTypes.NEW_REPORT_REQUEST]: newReportRequest,
    [ReportTypes.CHANGE_REPORT_VIEW]: changeReportView,
    [ReportTypes.RESET_REPORT_VIEW]: resetReportView
})

export default reducer
