import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType } from '../../root.types'
import { IActionLoadReport, ReportTypes } from './types'
import {
    loadActiveMinutesFailure,
    loadActiveMinutesSuccess,
    loadHeartRateFailure,
    loadHeartRateSuccess,
    loadStepsFailure,
    loadStepsSuccess
} from './actions'
import timeSeriesService from '../../../../services/time.series'
import TimeSeries, { TimeSeriesType } from '../../../application/models/time.series/time.series'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import moment from 'moment'
import { TimeSeriesIntervalFilter } from '../../../application/models/time.series/filters/interval.filter'
import { TimeSeriesInterval } from '../../../application/models/time.series/intraday/intraday.summary'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import UtilDatePicker from '../../../../components/date.and.hour/date'

function* loadSteps(action: IActionType<IActionLoadReport>) {
    try {
        const { patientId, startDate, endDate } = action.payload
        const filter: TimeSeriesSimpleFilter = new TimeSeriesSimpleFilter()
            .fromJSON({
                start_date: UtilDatePicker.formatDate(startDate, 'YYYY-MM-DD'),
                end_date: UtilDatePicker.formatDate(endDate, 'YYYY-MM-DD')
            })
        const steps: TimeSeries = yield apply(
            timeSeriesService,
            timeSeriesService.getResource,
            [patientId, TimeSeriesType.STEPS, filter]
        )
        yield put(loadStepsSuccess(steps))
    } catch (e) {
        yield put(loadStepsFailure())
    }
}

function* loadActiveMinutes(action: IActionType<IActionLoadReport>) {
    try {
        const { patientId, startDate, endDate } = action.payload
        const filter: TimeSeriesSimpleFilter = new TimeSeriesSimpleFilter()
            .fromJSON({
                start_date: UtilDatePicker.formatDate(startDate, 'YYYY-MM-DD'),
                end_date: UtilDatePicker.formatDate(endDate, 'YYYY-MM-DD')
            })
        const activeMinutes: TimeSeries = yield apply(
            timeSeriesService,
            timeSeriesService.getResource,
            [patientId, TimeSeriesType.ACTIVE_MINUTES, filter]
        )
        yield put(loadActiveMinutesSuccess(activeMinutes))
    } catch (e) {
        yield put(loadActiveMinutesFailure())
    }
}

function* loadHeartRate(action: IActionType<IActionLoadReport>) {
    try {
        const { patientId, startDate, endDate } = action.payload
        let currentDateValue: number = 0
        const endDateStr: number = endDate.getTime()
        const result: IntradayHeartRate[] = []
        let index: number = 0
        do {
            const currentDate = moment(startDate).add(index, 'days').format('YYYY-MM-DD')
            currentDateValue = moment(currentDate, 'YYYY-MM-DD').toDate().getTime()
            const filter: TimeSeriesIntervalFilter = new TimeSeriesIntervalFilter()
                .fromJSON({ date: currentDate, interval: TimeSeriesInterval.ONE_HOUR })
            const hearRate: IntradayHeartRate = yield apply(
                timeSeriesService,
                timeSeriesService.getResourceWithInterval,
                [patientId, TimeSeriesType.HEART_RATE, filter]
            )
            result.push(hearRate)
            index++
        } while (endDateStr > currentDateValue)
        yield put(loadHeartRateSuccess(result))
    } catch (e) {
        yield put(loadHeartRateFailure())
    }
}

export default function* reportSaga() {
    return yield all([
        takeLatest(ReportTypes.LOAD_STEPS_REQUEST, loadSteps),
        takeLatest(ReportTypes.LOAD_ACTIVE_MINUTES_REQUEST, loadActiveMinutes),
        takeLatest(ReportTypes.LOAD_HEART_RATE_REQUEST, loadHeartRate)
    ])
}
