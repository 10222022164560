import { JsonUtils } from '../../utils/json.util'
import Entity from '../entity'

export class MultiStatusItem<T extends Entity> {
    private _code: number | undefined
    private _item: T | undefined

    get code(): number | undefined {
        return this._code
    }

    set code(value: number | undefined) {
        this._code = value
    }

    get item(): T | undefined {
        return this._item
    }

    set item(value: T | undefined) {
        this._item = value
    }

    public fromJSON(json: any): MultiStatusItem<T> {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.code !== undefined) {
            this.code = json.code
        }

        if (json.item !== undefined) {
            this.item = json.item
        }

        return this
    }

    public toJSON(): any {
        return {
            code: this.code,
            item: this.item
        }
    }

}
