import Measurement from './measurement'
import { TypeMeasurement } from './measurements.last'

export default class TSGaitSpeed extends Measurement {
    private _value: number | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.TS_GAIT_SPEED
    }

    get value(): number | undefined {
        return parseFloat(`${this._value?.toFixed(2)}`)
    }

    set value(value: number | undefined) {
        this._value = value
    }

    public fromJSON(json: any): TSGaitSpeed {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = Number(json.value)
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined
        }
    }
}
