import Measurement from "./measurement"
import { TypeMeasurement } from "./measurements.last"

export default class BloodPressure extends Measurement {

    private _systolic: number | undefined
    private _diastolic: number | undefined
    private _pulse: number | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.BLOOD_PRESSURE
    }

    get systolic(): number | undefined {
        return this._systolic
    }

    set systolic(value: number | undefined) {
        this._systolic = value
    }

    get diastolic(): number | undefined {
        return this._diastolic
    }

    set diastolic(value: number | undefined) {
        this._diastolic = value
    }

    get pulse(): number | undefined {
        return this._pulse
    }

    set pulse(value: number | undefined) {
        this._pulse = value
    }

    public fromJSON(json: any): BloodPressure {
        super.fromJSON(json)

        if (json.systolic !== undefined) {
            this.systolic = json.systolic
        }

        if (json.diastolic !== undefined) {
            this.diastolic = json.diastolic
        }

        if (json.pulse !== undefined) {
            this.pulse = json.pulse
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            systolic: this.systolic ? this.systolic : undefined,
            diastolic: this.diastolic ? this.diastolic : undefined,
            pulse: this.pulse ? this.pulse : undefined
        }
    }
}
