import { JsonUtils } from '../../../utils/json.util'

export enum TimeSeriesInterval {
    ONE_SECOND = '1s',
    FIFTEEN_SECONDS = '15s',
    ONE_MINUTE = '1m',
    FIFTEEN_MINUTES = '15m',
    ONE_HOUR = '1h'
}

export default class IntradaySummary {
    private _start_time: string | undefined
    private _end_time: string | undefined
    private _total: number | undefined
    private _interval: TimeSeriesInterval | undefined

    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        this._start_time = value
    }

    get end_time(): string | undefined {
        return this._end_time
    }

    set end_time(value: string | undefined) {
        this._end_time = value
    }

    get total(): number | undefined {
        return this._total
    }

    set total(value: number | undefined) {
        this._total = value
    }

    get interval(): TimeSeriesInterval | undefined {
        return this._interval
    }

    set interval(value: TimeSeriesInterval | undefined) {
        this._interval = value
    }

    public fromJSON(json: any): IntradaySummary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.end_time !== undefined) {
            this.end_time = json.end_time
        }

        if (json.total !== undefined) {
            this.total = json.total
        }

        if (json.interval !== undefined) {
            this.interval = json.interval
        }

        return this
    }

    public toJSON(): any {
        return {
            start_time: this.start_time ? this.start_time : undefined,
            end_time: this.end_time ? this.end_time : undefined,
            total: this.total ? this.total : undefined,
            interval: this.interval ? this.interval : undefined
        }
    }
}
