import { JsonUtils } from '../../../utils/json.util'

export default class NightAwakening {
    private _start_time: string | undefined
    private _end_time: string | undefined
    private _duration: number | undefined
    private _steps: number | undefined

    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        this._start_time = value
    }

    get end_time(): string | undefined {
        return this._end_time
    }

    set end_time(value: string | undefined) {
        this._end_time = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    get steps(): number | undefined {
        return this._steps
    }

    set steps(value: number | undefined) {
        this._steps = value
    }

    public fromJSON(json: any): NightAwakening {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.end_time !== undefined) {
            this.end_time = json.end_time
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        if (json.steps !== undefined) {
            this.steps = json.steps
        }

        return this
    }

    public toJSON(): any {
        return {
            start_time: this.start_time ? this.start_time : undefined,
            end_time: this.end_time ? this.end_time : undefined,
            duration: this.duration ? this.duration : undefined,
            steps: this.steps ? this.steps : undefined
        }
    }
}
