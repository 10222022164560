import Measurement from "./measurement"
import { TypeMeasurement } from "./measurements.last"

export enum MealType {
    PREPRANDIAL = 'preprandial',
    POSTPRANDIAL = 'postprandial',
    FASTING = 'fasting',
    CASUAL = 'casual',
    BEDTIME = 'bedtime'
}

export const MAP_MEAL_TYPE = {
    [MealType.PREPRANDIAL]: 'PATIENTS.MEASUREMENTS.BLOOD_GLUCOSE.PREPRANDIAL',
    [MealType.POSTPRANDIAL]: 'PATIENTS.MEASUREMENTS.BLOOD_GLUCOSE.POSTPRANDIAL',
    [MealType.FASTING]: 'PATIENTS.MEASUREMENTS.BLOOD_GLUCOSE.FASTING',
    [MealType.CASUAL]: 'PATIENTS.MEASUREMENTS.BLOOD_GLUCOSE.CASUAL',
    [MealType.BEDTIME]: 'PATIENTS.MEASUREMENTS.BLOOD_GLUCOSE.BEDTIME'
}

export default class BloodGlucose extends Measurement {

    private _value: number | undefined
    private _meal: MealType | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.BLOOD_GLUCOSE
        this._meal = '' as MealType
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    get meal(): MealType | undefined {
        return this._meal
    }

    set meal(value: MealType | undefined) {
        this._meal = value
    }

    public fromJSON(json: any): BloodGlucose {
        super.fromJSON(json)

        if (json.value !== undefined) {
            this.value = json.value
        }

        if (json.meal !== undefined) {
            this.meal = json.meal
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            value: this.value ? this.value : undefined,
            meal: this.meal ? this.meal : undefined
        }
    }
}
