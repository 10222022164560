import { all, apply, put, takeLatest } from 'redux-saga/effects'

import Sleep from '../../../application/models/activity/sleep/sleep'
import { IActionType, IAxiosResponse } from '../../root.types'
import { IActionChartLoadSleep, IActionFindSleep, IActionLoadSleep, IActionRemoveSleep, SleepTypes } from './types'
import {
    chartLoadSleepFailure,
    chartLoadSleepSuccess,
    findSleepFailure,
    findSleepSuccess,
    loadHeartRateFailure,
    loadHeartRateSuccess,
    loadMoreSleepFailure,
    loadMoreSleepSuccess,
    loadSleepFailure,
    loadSleepSuccess,
    removeSleepFailure,
    removeSleepSuccess
} from './actions'
import { open } from '../../snack.bar/actions'
import { SnackBarMessageType } from '../../../../components/snackbar'
import sleepService from '../../../../services/sleep'
import { IActionLoadTimeSeries } from '../activity/types'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import timeSeriesService from '../../../../services/time.series'
import { TimeSeriesType } from '../../../application/models/time.series/time.series'
import { push } from 'connected-react-router'

function* findSleep(action: IActionType<IActionFindSleep>) {
    try {
        const { patientId, sleepId } = action.payload
        const sleep: Sleep = yield apply(
            sleepService,
            sleepService.getById,
            [patientId, sleepId]
        )
        yield put<any>(findSleepSuccess(sleep))
    } catch (e) {
        yield put(findSleepFailure())
    }
}

function* loadSleep(action: IActionType<IActionLoadSleep>) {
    try {
        const { patientId, paginator, filter } = action.payload
        const response: IAxiosResponse<Sleep[]> = yield apply(
            sleepService,
            sleepService.getAll,
            [patientId, paginator, filter]
        )
        yield put(loadSleepSuccess(response))
    } catch (e) {
        yield put(loadSleepFailure())
    }
}

function* loadMoreSleep(action: IActionType<IActionLoadSleep>) {
    try {
        const { patientId, paginator, filter } = action.payload
        const response: IAxiosResponse<Sleep[]> = yield apply(
            sleepService,
            sleepService.getAll,
            [patientId, paginator, filter]
        )
        yield put(loadMoreSleepSuccess(response))
    } catch (e) {
        yield put(loadMoreSleepFailure())
    }
}

function* removeSleep(action: IActionType<IActionRemoveSleep>) {
    try {
        const { patientId, ids, location } = action.payload
        for (const sleepId of ids) {
            yield apply(
                sleepService,
                sleepService.remove,
                [patientId, sleepId]
            )
        }
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.ACTIVITIES.REMOVE.REMOVED_SUCCESS')
        )
        if (location === 'DETAILS') {
            yield put(push(`/app/patients/${patientId}/sleep`))
        }
        yield put(removeSleepSuccess(ids))
    } catch (e) {
        yield put(removeSleepFailure())
    }
}

function* loadHeartRate(action: IActionType<IActionLoadTimeSeries>) {
    try {
        const { patientId, filter } = action.payload
        const hearRate: IntradayHeartRate = yield apply(
            timeSeriesService,
            timeSeriesService.getResourceWithTime,
            [patientId, TimeSeriesType.HEART_RATE, filter]
        )
        yield put(loadHeartRateSuccess(hearRate))
    } catch (e) {
        yield put(loadHeartRateFailure())
    }
}

function* chartLoadSleep(action: IActionType<IActionChartLoadSleep>) {
    try {
        const { patientId, filter } = action.payload
        const response: IAxiosResponse<Sleep[]> = yield apply(
            sleepService,
            sleepService.getAll,
            [patientId, undefined, filter]
        )
        yield put(chartLoadSleepSuccess(response))
    } catch (e) {
        yield put(chartLoadSleepFailure())
    }
}

export default function* sleepSaga() {
    return yield all([
        takeLatest(SleepTypes.LOAD_SLEEP_REQUEST, loadSleep),
        takeLatest(SleepTypes.FIND_REQUEST, findSleep),
        takeLatest(SleepTypes.LOAD_MORE_SLEEP_REQUEST, loadMoreSleep),
        takeLatest(SleepTypes.REMOVE_SLEEP_REQUEST, removeSleep),
        takeLatest(SleepTypes.LOAD_HEART_RATE_REQUEST, loadHeartRate),
        takeLatest(SleepTypes.CHART_SLEEP_REQUEST, chartLoadSleep)
    ])
}
