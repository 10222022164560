import { action } from 'typesafe-actions'
import Sleep from '../../../application/models/activity/sleep/sleep'
import { IAxiosResponse, IPaginator } from '../../root.types'
import { SleepTypes } from './types'
import { TimeSeriesFullFilter } from '../../../application/models/time.series/filters/full.filter'
import UtilDatePicker from '../../../../components/date.and.hour/date'
import { TimeSeriesInterval } from '../../../application/models/time.series/intraday/intraday.summary'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'
import { ActivityTypes } from '../activity/types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'

export const resetList = () => action(ActivityTypes.RESET_LIST)

export const resetDetails = () => action(ActivityTypes.RESET_DETAILS)

/* Actions to find a sleep */
export const findSleepRequest = (patientId: string, sleepId: string) => action(
    SleepTypes.FIND_REQUEST,
    { patientId, sleepId }
)

export const findSleepSuccess = (sleep: Sleep) => {
    const patientId: string = sleep.patient_id || ''
    const timeSeriesFilter: TimeSeriesFullFilter = new TimeSeriesFullFilter()
        .fromJSON({
            start_date: UtilDatePicker.formatDate(sleep?.start_time, 'YYYY-MM-DD'),
            end_date: UtilDatePicker.formatDate(sleep?.end_time, 'YYYY-MM-DD'),
            start_time: UtilDatePicker.formatDate(sleep?.start_time, 'HH:mm:ss'),
            end_time: UtilDatePicker.formatDate(sleep?.end_time, 'HH:mm:ss'),
            interval: TimeSeriesInterval.ONE_SECOND
        })
    return [
        action(SleepTypes.FIND_SUCCESS, { sleep }),
        loadHeartRateRequest(patientId, timeSeriesFilter)
    ]
}

export const findSleepFailure = () => action(SleepTypes.FIND_FAILURE)

/* Load heart rate during sleep */
export const loadHeartRateRequest = (patientId: string, filter: TimeSeriesFullFilter) => action(
    SleepTypes.LOAD_HEART_RATE_REQUEST,
    { patientId, filter }
)

export const loadHeartRateSuccess = (heartRate: IntradayHeartRate) => action(
    SleepTypes.LOAD_HEART_RATE_SUCCESS,
    { heartRate }
)

export const loadHeartRateFailure = () => action(SleepTypes.LOAD_HEART_RATE_FAILURE)

/* Actions for sleep */
export const changeListSleepPaginator = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => [
    action(SleepTypes.CHANGE_SLEEP_PAGINATOR, { patientId, paginator, filter }),
    loadSleepRequest(patientId, paginator, filter)
]

export const loadSleepRequest = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => action(
    SleepTypes.LOAD_SLEEP_REQUEST,
    { patientId, paginator, filter }
)

export const loadSleepSuccess = (response: IAxiosResponse<Sleep[]>) => action(
    SleepTypes.LOAD_SLEEP_SUCCESS, {
        sleeps: response.data,
        headers: response.headers
    })

export const loadSleepFailure = () => action(SleepTypes.LOAD_SLEEP_FAILURE)

export const loadMoreSleepRequest = (
    patientId: string,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => action(
    SleepTypes.LOAD_MORE_SLEEP_REQUEST,
    { patientId, paginator, filter }
)

export const loadMoreSleepSuccess = (response: IAxiosResponse<Sleep[]>) => action(
    SleepTypes.LOAD_MORE_SLEEP_SUCCESS,
    {
        sleeps: response.data,
        headers: response.headers
    }
)

export const loadMoreSleepFailure = () => action(SleepTypes.LOAD_MORE_SLEEP_FAILURE)

export const changeDialogRemoveSleep = (dialog: boolean, ids: string[]) => action(
    SleepTypes.CHANGE_DIALOG_REMOVE_SLEEP,
    {
        dialog,
        ids
    }
)

export const removeSleepRequest = (
    patientId: string,
    ids: string[],
    location: 'LIST' | 'DETAILS'
) => action(
    SleepTypes.REMOVE_SLEEP_REQUEST,
    { patientId, ids, location }
)

export const removeSleepSuccess = (ids: string[]) => action(
    SleepTypes.REMOVE_SLEEP_SUCCESS,
    { ids }
)

export const removeSleepFailure = () => action(SleepTypes.REMOVE_SLEEP_FAILURE)

/* Actions for load sleep chart */

export const chartLoadSleepRequest = (patientId: string, filter: TimeSeriesSimpleFilter) => action(
    SleepTypes.CHART_SLEEP_REQUEST,
    { patientId, filter }
)

export const chartLoadSleepSuccess = (response: IAxiosResponse<Sleep[]>) => action(
    SleepTypes.CHART_SLEEP_SUCCESS, {
        sleeps: response.data,
        headers: response.headers
    })

export const chartLoadSleepFailure = () => action(SleepTypes.CHART_SLEEP_FAILURE)
