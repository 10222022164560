import axiosInstance from '../axios'
import { AxiosResponse } from 'axios'
import localStorageService from '../local.storage'

class AvatarService {

    constructor(private apiVersion: string = 'v1') {
    }

    public save(userId: string, avatar: any) {
        const data = new FormData()
        data.append('avatar', avatar)
        return axiosInstance.put(`${this.apiVersion}/users/${userId}/avatar`, data, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        })
    }

    public get(userId: string, saveInStorage?: boolean): Promise<any> {
        return axiosInstance.get(`${this.apiVersion}/users/${userId}/avatar`, { responseType: 'blob' })
            .then((response: AxiosResponse) => {
                if (saveInStorage) {
                    const reader = new FileReader()
                    reader.readAsDataURL(response.data)
                    reader.onloadend = () => {
                        const base64data = reader.result
                        if (base64data && typeof base64data === 'string') {
                            localStorageService.setItem('avatar', base64data)
                        }
                    }
                }
                return URL.createObjectURL(response.data)
            })
    }

    public remove(userId: string) {
        return axiosInstance.delete(`${this.apiVersion}/users/${userId}/avatar`)
    }

}

export default new AvatarService()
