import { createTypes } from 'reduxsauce'
import { IComponentState, IPaginator } from '../../root.types'
import Device from '../../../application/models/device/device'
import DeviceCode from '../../../application/models/device/code'

/**
 * Device types
 */
export const DeviceTypes = createTypes(`
    RESET_LIST
    RESET_CREATE
    
    CREATE_REQUEST
    CREATE_SUCCESS
    CREATE_FAILURE
    CHANGE_DIALOG_CREATE
    
    FIND_REQUEST
    FIND_SUCCESS
    FIND_FAILURE
    
    UPDATE_REQUEST
    UPDATE_SUCCESS
    UPDATE_FAILURE
    
    LOAD_ALL_REQUEST
    LOAD_REQUEST
    LOAD_SUCCESS
    LOAD_FAILURE
    CHANGE_PAGINATOR
    CHANGE_SEARCH_PAGINATOR
    
    LOAD_MORE_REQUEST
    LOAD_MORE_SUCCESS
    LOAD_MORE_FAILURE
    
    CHANGE_DIALOG_REMOVE
    
    REMOVE_REQUEST
    REMOVE_SUCCESS
    REMOVE_FAILURE
    
    GENERATE_CODE_REQUEST
    GENERATE_CODE_SUCCESS
    GENERATE_CODE_FAILURE
    GENERATE_CODE_CLOSE_DIALOG          
    `,
    {
        prefix: '@patient.devices/'
    }
)

/**
 * Action Types
 */
export interface IActionFind {
    readonly patientId: string
    readonly deviceId: string
}

export interface IActionCreate {
    readonly device: Device
}

export interface IActionLoad {
    readonly patientId: string
    readonly paginator?: IPaginator
}

export interface IActionRemove {
    readonly patientId: string
    readonly ids: string[]
}

export interface IActionGenerateCode {
    readonly deviceId: string
}

/**
 * Device State
 */
interface IListState extends IComponentState {
    readonly devices: Device[]
    readonly paginator: IPaginator

}

export interface IRemoveState extends IComponentState {
    readonly ids: string[]
    readonly dialog: boolean
}

export interface ICreateState extends IComponentState {
    readonly device: Device
    readonly dialog: boolean
}

export interface ICodeComponent extends IComponentState {
    readonly deviceCode: DeviceCode
    readonly dialog: boolean
}

/**
 * State type
 */
export interface IDeviceState {
    readonly create: ICreateState
    readonly list: IListState
    readonly remove: IRemoveState
    readonly authenticateCode: ICodeComponent
}
