import { JsonUtils } from '../../../utils/json.util'
import HeartRateData from './heart.rate.data'

export default class HeartRateZone {
    private _fat_burn: HeartRateData | undefined
    private _cardio: HeartRateData | undefined
    private _peak: HeartRateData | undefined
    private _out_of_range: HeartRateData | undefined

    get fat_burn(): HeartRateData | undefined {
        return this._fat_burn
    }

    set fat_burn(value: HeartRateData | undefined) {
        this._fat_burn = value
    }

    get cardio(): HeartRateData | undefined {
        return this._cardio
    }

    set cardio(value: HeartRateData | undefined) {
        this._cardio = value
    }

    get peak(): HeartRateData | undefined {
        return this._peak
    }

    set peak(value: HeartRateData | undefined) {
        this._peak = value
    }

    get out_of_range(): HeartRateData | undefined {
        return this._out_of_range
    }

    set out_of_range(value: HeartRateData | undefined) {
        this._out_of_range = value
    }

    public fromJSON(json: any): HeartRateZone {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.fat_burn !== undefined) {
            this.fat_burn = new HeartRateData().fromJSON(json.fat_burn)
        }

        if (json.cardio !== undefined) {
            this.cardio = new HeartRateData().fromJSON(json.cardio)
        }

        if (json.peak !== undefined) {
            this.peak = new HeartRateData().fromJSON(json.peak)
        }

        if (json.out_of_range !== undefined) {
            this.out_of_range = new HeartRateData().fromJSON(json.out_of_range)
        }

        return this
    }

    public toJSON(): any {
        return {
            fat_burn: this.fat_burn ? this.fat_burn.toJSON() : undefined,
            cardio: this.cardio ? this.cardio.toJSON() : undefined,
            peak: this.peak ? this.peak.toJSON() : undefined,
            out_of_range: this.out_of_range ? this.out_of_range.toJSON() : undefined
        }
    }
}
