import React from 'react'
import { Typography } from '@material-ui/core'
import { useTranslation } from 'react-i18next'
import { Variant } from '@material-ui/core/styles/createTypography'

export const DurationToTypography = (props: { id: string, duration: number, variant: Variant, complement?: string }) => {
    const { id, duration, variant, complement } = props
    const hoursTruncate = DurationTransform.getHoursTruncate(duration)
    const minutes = DurationTransform.getMinutes(duration)
    const seconds = DurationTransform.getSeconds(duration)
    const { t } = useTranslation()
    return <Typography id={id} variant={variant} noWrap={true}>
        {
            !!hoursTruncate && <React.Fragment>
                {hoursTruncate}<small>{t('UNITS_OF_MEASURES.HOURS')} </small>
            </React.Fragment>
        }
        {
            ((!!hoursTruncate && !!minutes) || (!!hoursTruncate && !!seconds)) && (
                <small>{t('DEFAULT.AND')} </small>
            )
        }
        {
            !!minutes && <React.Fragment>
                {minutes}<small>{t('UNITS_OF_MEASURES.MINUTES')} </small>
            </React.Fragment>
        }
        {
            !!seconds && <React.Fragment>
                {seconds}<small>{t('UNITS_OF_MEASURES.SECONDS')} </small>
            </React.Fragment>
        }
        {
            !!complement && <small>{complement}</small>
        }
    </Typography>
}

export const Duration = (props: { value: number }) => {
    const { value } = props
    const { t } = useTranslation()
    const hoursTruncate = DurationTransform.getHoursTruncate(value)
    const minutes = DurationTransform.getMinutes(value)
    const seconds = DurationTransform.getSeconds(value)
    return <React.Fragment>
        {
            !!hoursTruncate && <React.Fragment>
                {hoursTruncate}<small>{t('UNITS_OF_MEASURES.HOURS')} </small>
            </React.Fragment>
        }
        {!!minutes && <React.Fragment>
            {minutes}<small>{t('UNITS_OF_MEASURES.MINUTES')} </small>
        </React.Fragment>}
        {!!seconds && <React.Fragment>{seconds}<small>{t('UNITS_OF_MEASURES.SECONDS')}</small></React.Fragment>}
    </React.Fragment>
}

class DurationTransform {

    public static millisecondsToHours(milliseconds?: number): number {
        if (!milliseconds) {
            return 0
        }
        return (milliseconds / 3.6) * Math.pow(10, -3) * Math.pow(10, -3)
    }

    public static hoursToMilliseconds(hours?: number): number {
        if (!hours) {
            return 0
        }
        return (hours * 3.6) * Math.pow(10, 3) * Math.pow(10, 3)
    }

    public static getHoursTruncate(duration: number): number {
        return Math.floor(duration / 3600000)
    }

    public static getMinutes(duration: number): number {
        return Math.floor((duration % 3600000) / 60000)
    }

    public static getSeconds(duration: number): number {
        return Math.floor(((duration % 3600000) % 60000) / 1000)
    }

    public static getYearsTruncate(duration: number): number {
        return Math.floor(duration / 31540000000)
    }

}

export default DurationTransform
