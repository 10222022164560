import { JsonUtils } from '../../../utils/json.util'
import { SleepType } from '../../activity/sleep/sleep'

export class TimeSeriesSimpleFilter {
    private _start_date: string | undefined
    private _end_date: string | undefined
    private _type: SleepType | undefined

    get start_date(): string | undefined {
        return this._start_date
    }

    set start_date(value: string | undefined) {
        value && value?.length > 10 ?
            this._start_date = value?.substr(0, 10)
            : this._start_date = value
    }

    get end_date(): string | undefined {
        return this._end_date
    }

    set end_date(value: string | undefined) {
        value && value?.length > 10 ?
            this._end_date = value?.substr(0, 10)
            : this._end_date = value
    }

    get type(): SleepType | undefined {
        return this._type
    }

    set type(value: SleepType | undefined) {
        this._type = value
    }

    public fromJSON(json: any): TimeSeriesSimpleFilter {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.start_date !== undefined) {
            this.start_date = json.start_date
        }

        if (json.end_date !== undefined) {
            this.end_date = json.end_date
        }


        if (json.type !== undefined) {
            this.type = json.type
        }

        return this
    }

    public toJSON(): any {
        return {
            start_date: this.start_date ? this.start_date : undefined,
            end_date: this.end_date ? this.end_date : undefined,
            type: this.type ? this.type : undefined
        }
    }
}
