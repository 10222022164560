import { JsonUtils } from '../../utils/json.util'
import BloodGlucose from './blood.glucose'
import BloodPressure from './blood.pressure'
import BodyFat from './body.fat'
import CalfCircumference from './calf.circumference'
import HandGrip from './hand.grip'
import Height from './height'
import WaistCircumference from './waist.circumference'
import Weight from './weight'
import BodyTemperature from './body.temperature'
import OxygenSaturation from './oxygen.saturation'
import GaitSpeed from './gait.speed'
import TSGaitSpeed from './ts.gait.speed'
import ManualGaitSpeed from './manual.gait.speed'

export enum TypeMeasurement {
    BLOOD_GLUCOSE = 'blood_glucose',
    BLOOD_PRESSURE = 'blood_pressure',
    BODY_TEMPERATURE = 'body_temperature',
    WEIGHT = 'weight',
    BODY_FAT = 'body_fat',
    HEIGHT = 'height',
    WAIST_CIRCUMFERENCE = 'waist_circumference',
    HAND_GRIP = 'hand_grip',
    CALF_CIRCUMFERENCE = 'calf_circumference',
    HEART_RATE_VARIABILITY = 'heart_rate_variability',
    OXYGEN_SATURATION = 'oxygen_saturation',
    GAIT_SPEED = 'gait_speed',
    TS_GAIT_SPEED = 'ts_gait_speed',
    MANUAL_GAIT_SPEED = 'manual_gait_speed'
}

export enum UnitMeasurement {
    BPM = 'bpm',
    KG = 'kg',
    KGF = 'kgf',
    LBS = 'lbs',
    DEGREES_CELSIUS = 'ºC',
    DEGRESS_FAHRENHEIT = 'ºF',
    CM = 'cm',
    M = 'm',
    PERCENTAGE = '%',
    MMHG = 'mmHg',
    MD_DL = 'md/dl',
    MS = 'm/s',
    S = 's'
}

export const MAP_UNIT_MEASUREMENT = {
    [UnitMeasurement.BPM]: 'UNITS_OF_MEASURES.NOMENCLATURE.BPM',
    [UnitMeasurement.KG]: 'UNITS_OF_MEASURES.NOMENCLATURE.KG',
    [UnitMeasurement.KGF]: 'UNITS_OF_MEASURES.NOMENCLATURE.KGF',
    [UnitMeasurement.LBS]: 'UNITS_OF_MEASURES.NOMENCLATURE.LBS',
    [UnitMeasurement.DEGREES_CELSIUS]: 'UNITS_OF_MEASURES.NOMENCLATURE.DEGREES_CELSIUS',
    [UnitMeasurement.DEGRESS_FAHRENHEIT]: 'UNITS_OF_MEASURES.NOMENCLATURE.DEGRESS_FAHRENHEIT',
    [UnitMeasurement.CM]: 'UNITS_OF_MEASURES.NOMENCLATURE.CM',
    [UnitMeasurement.M]: 'UNITS_OF_MEASURES.NOMENCLATURE.M',
    [UnitMeasurement.PERCENTAGE]: 'UNITS_OF_MEASURES.NOMENCLATURE.PERCENTAGE',
    [UnitMeasurement.MMHG]: 'UNITS_OF_MEASURES.NOMENCLATURE.MMHG',
    [UnitMeasurement.MD_DL]: 'UNITS_OF_MEASURES.NOMENCLATURE.MD_DL',
    [UnitMeasurement.MS]: 'UNITS_OF_MEASURES.NOMENCLATURE.MS',
    [UnitMeasurement.S]: 'UNITS_OF_MEASURES.NOMENCLATURE.S'
}

export default class LastMeasurements {

    private _blood_glucose: BloodGlucose | undefined
    private _blood_pressure: BloodPressure | undefined
    private _body_temperature: BodyTemperature | undefined
    private _weight: Weight | undefined
    private _body_fat: BodyFat | undefined
    private _height: Height | undefined
    private _waist_circumference: WaistCircumference | undefined
    private _hand_grip: HandGrip | undefined
    private _calf_circumference: CalfCircumference | undefined
    private _oxygen_saturation: OxygenSaturation | undefined
    private _gait_speed: GaitSpeed | undefined
    private _ts_gait_speed: TSGaitSpeed | undefined
    private _manual_gait_speed: ManualGaitSpeed | undefined

    constructor() {
        this._blood_glucose = new BloodGlucose()
        this._blood_pressure = new BloodPressure()
        this._body_temperature = new BodyTemperature()
        this._weight = new Weight()
        this._body_fat = new BodyFat()
        this._height = new Height()
        this._waist_circumference = new WaistCircumference()
        this._hand_grip = new HandGrip()
        this._calf_circumference = new CalfCircumference()
        this._oxygen_saturation = new OxygenSaturation()
        this._gait_speed = new GaitSpeed()
        this._ts_gait_speed = new TSGaitSpeed()
        this._manual_gait_speed = new ManualGaitSpeed()
    }

    get blood_glucose(): BloodGlucose | undefined {
        return this._blood_glucose
    }

    set blood_glucose(value: BloodGlucose | undefined) {
        this._blood_glucose = value
    }

    get blood_pressure(): BloodPressure | undefined {
        return this._blood_pressure
    }

    set blood_pressure(value: BloodPressure | undefined) {
        this._blood_pressure = value
    }

    get body_temperature(): BodyTemperature | undefined {
        return this._body_temperature
    }

    set body_temperature(value: BodyTemperature | undefined) {
        this._body_temperature = value
    }

    get weight(): Weight | undefined {
        return this._weight
    }

    set weight(value: Weight | undefined) {
        this._weight = value
    }

    get body_fat(): BodyFat | undefined {
        return this._body_fat
    }

    set body_fat(value: BodyFat | undefined) {
        this._body_fat = value
    }

    get height(): Height | undefined {
        return this._height
    }

    set height(value: Height | undefined) {
        this._height = value
    }

    get waist_circumference(): WaistCircumference | undefined {
        return this._waist_circumference
    }

    set waist_circumference(value: WaistCircumference | undefined) {
        this._waist_circumference = value
    }

    get hand_grip(): HandGrip | undefined {
        return this._hand_grip
    }

    set hand_grip(value: HandGrip | undefined) {
        this._hand_grip = value
    }

    get calf_circumference(): CalfCircumference | undefined {
        return this._calf_circumference
    }

    set calf_circumference(value: CalfCircumference | undefined) {
        this._calf_circumference = value
    }

    get oxygen_saturation(): OxygenSaturation | undefined {
        return this._oxygen_saturation
    }

    set oxygen_saturation(value: OxygenSaturation | undefined) {
        this._oxygen_saturation = value
    }

    get gait_speed(): GaitSpeed | undefined {
        return this._gait_speed
    }

    set gait_speed(value: GaitSpeed | undefined) {
        this._gait_speed = value
    }

    get ts_gait_speed(): TSGaitSpeed | undefined {
        return this._ts_gait_speed
    }

    set ts_gait_speed(value: TSGaitSpeed | undefined) {
        this._ts_gait_speed = value
    }

    get manual_gait_speed(): ManualGaitSpeed | undefined {
        return this._manual_gait_speed
    }

    set manual_gait_speed(value: ManualGaitSpeed | undefined) {
        this._manual_gait_speed = value
    }

    public fromJSON(json: any): LastMeasurements {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.blood_glucose !== undefined) {
            this.blood_glucose = new BloodGlucose().fromJSON(json.blood_glucose)
        }

        if (json.blood_pressure !== undefined) {
            this.blood_pressure = new BloodPressure().fromJSON(json.blood_pressure)
        }

        if (json.body_temperature !== undefined) {
            this.body_temperature = new BodyTemperature().fromJSON(json.body_temperature)
        }

        if (json.weight !== undefined) {
            this.weight = new Weight().fromJSON(json.weight)
        }

        if (json.body_fat !== undefined) {
            this.body_fat = new BodyFat().fromJSON(json.body_fat)
        }

        if (json.height !== undefined) {
            this.height = new Height().fromJSON(json.height)
        }

        if (json.waist_circumference !== undefined) {
            this.waist_circumference = new WaistCircumference().fromJSON(json.waist_circumference)
        }

        if (json.hand_grip !== undefined) {
            this.hand_grip = new HandGrip().fromJSON(json.hand_grip)
        }

        if (json.calf_circumference !== undefined) {
            this.calf_circumference = new CalfCircumference().fromJSON(json.calf_circumference)
        }

        if (json.oxygen_saturation !== undefined) {
            this.oxygen_saturation = new OxygenSaturation().fromJSON(json.oxygen_saturation)
        }

        if (json.gait_speed !== undefined) {
            this.gait_speed = new GaitSpeed().fromJSON(json.gait_speed)
        }

        if (json.ts_gait_speed !== undefined) {
            this.ts_gait_speed = new TSGaitSpeed().fromJSON(json.ts_gait_speed)
        }

        if (json.manual_gait_speed !== undefined) {
            this.manual_gait_speed = new ManualGaitSpeed().fromJSON(json.manual_gait_speed)
        }

        return this
    }

    public toJSON(): any {
        return {
            blood_glucose: this.blood_glucose ? this.blood_glucose.toJSON() : undefined,
            blood_pressure: this.blood_pressure ? this.blood_pressure.toJSON() : undefined,
            body_temperature: this.body_temperature ? this.body_temperature.toJSON() : undefined,
            weight: this.weight ? this.weight.toJSON() : undefined,
            body_fat: this.body_fat ? this.body_fat.toJSON() : undefined,
            height: this.height ? this.height.toJSON() : undefined,
            waist_circumference: this.waist_circumference ? this.waist_circumference.toJSON() : undefined,
            hand_grip: this.hand_grip ? this.hand_grip.toJSON() : undefined,
            calf_circumference: this.calf_circumference ? this.calf_circumference.toJSON() : undefined,
            oxygen_saturation: this.oxygen_saturation ? this.oxygen_saturation.toJSON() : undefined,
            gait_speed: this.gait_speed ? this.gait_speed.toJSON() : undefined,
            ts_gait_speed: this.ts_gait_speed ? this.ts_gait_speed.toJSON() : undefined,
            manual_gait_speed: this.manual_gait_speed ? this.manual_gait_speed.toJSON() : undefined
        }
    }
}
