import { Reducer } from 'redux'
import { INoteState, NoteTypes } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../../root.types'
import moment from 'moment'
import Note from '../../../application/models/note/note'
import Diagnosis from '../../../application/models/diagnosis/diagnosis'

export const INITIAL_STATE: INoteState = {
    create: {
        note: new Diagnosis(),
        loading: false,
        success: false,
        error: false,
        dialog: false
    },
    list: {
        data: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        success: false,
        error: false
    }
}

export const resetList = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const resetCreate = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        create: INITIAL_STATE.create
    }
}

/* Actions to find note  */

export const findRequest = (state: INoteState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const findSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ note: Note }>) => {
    const { note } = action.payload
    return {
        ...state,
        create: success({ ...state.create, note })
    }
}

export const findFailure = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        create: failure(state.create)
    }
}

/* Actions to create note  */

export const changeDialogCreate = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ dialog: boolean, note: Note }>) => {
    const { note, dialog } = action.payload
    return {
        ...state,
        create: {
            ...state.create,
            dialog,
            note
        }
    }
}


export const createRequest = (state: INoteState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ note: Note }>) => {
    const { note } = action.payload
    const listResult = [note].concat(state.list.data)
    return {
        ...state,
        create: {
            ...INITIAL_STATE.create,
            note,
            dialog: true
        },
        list: success({
            ...state.list,
            data: listResult
        })
    }
}

export const createFailure = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        create: failure(state.create)
    }
}

/* Actions to update note  */

export const updateSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ note: Note }>) => {
    const { note } = action.payload
    const listResult =
        state.list.data
            .map((element: Note) => {
                if (element.id === note.id) {
                    return note
                }
                return element
            })
            .sort((first: Note, second: Note) => {
                const firstDate = moment(first.date)
                const secondDate = moment(second.date)
                return firstDate.isAfter(secondDate) ? -1 : 1
            })
    return {
        ...state,
        create: success(state.create),
        list: success({
            ...state.list,
            data: listResult
        })
    }
}

/* Actions to load notes  */

export const changeSearchPaginator = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ search: ISearch }>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeListPaginator = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return {
        ...state,
        list: { ...state.list, paginator }
    }
}

export const loadRequest = (state: INoteState = INITIAL_STATE, action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return { ...state, list: request({ ...state.list, paginator }) }
}

export const loadSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ data: Note[], headers: any }>) => {
    const { headers, data } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({ ...state.list, paginator, data })
    }
}

export const loadFailure = (state: INoteState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ data: Note[], headers: any }>) => {
    const { headers, data } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({
            ...state.list,
            paginator,
            data: state.list.data.concat(data)
        })
    }
}

/* Actions to remove note  */

export const changeDialogRemove = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ dialog: true, ids: string[] }>) => {
    const { dialog, ids } = action.payload
    return {
        ...state,
        remove: {
            ...state.remove,
            dialog,
            ids
        }
    }
}

export const removeRequest = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        remove: request({ ...state.remove, dialog: true })
    }
}

export const removeSuccess = (
    state: INoteState = INITIAL_STATE,
    action: IActionType<{ ids: string[] }>) => {
    const { ids } = action.payload
    const data = state
        .list
        .data
        ?.filter((note: Note) => !ids.some((removed: string) => note?.id === removed))
    return {
        ...state,
        remove: success({ ...INITIAL_STATE.remove }),
        list: {
            ...state.list,
            data
        }

    }
}

export const removeFailure = (state: INoteState = INITIAL_STATE) => {
    return {
        ...state,
        remove: failure(state.remove)
    }
}


const reducer: Reducer<INoteState> = createReducer<INoteState>(INITIAL_STATE, {
    [NoteTypes.RESET_LIST]: resetList,
    [NoteTypes.RESET_CREATE]: resetCreate,

    [NoteTypes.CHANGE_PAGINATOR]: changeListPaginator,
    [NoteTypes.CHANGE_DIALOG_REMOVE]: changeDialogRemove,

    [NoteTypes.CHANGE_DIALOG_CREATE]: changeDialogCreate,
    [NoteTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [NoteTypes.CREATE_REQUEST]: createRequest,
    [NoteTypes.CREATE_SUCCESS]: createSuccess,
    [NoteTypes.CREATE_FAILURE]: createFailure,

    [NoteTypes.FIND_REQUEST]: findRequest,
    [NoteTypes.FIND_SUCCESS]: findSuccess,
    [NoteTypes.FIND_FAILURE]: findFailure,

    [NoteTypes.UPDATE_REQUEST]: createRequest,
    [NoteTypes.UPDATE_SUCCESS]: updateSuccess,
    [NoteTypes.UPDATE_FAILURE]: createFailure,

    [NoteTypes.LOAD_REQUEST]: loadRequest,
    [NoteTypes.LOAD_SUCCESS]: loadSuccess,
    [NoteTypes.LOAD_FAILURE]: loadFailure,

    [NoteTypes.LOAD_MORE_REQUEST]: loadRequest,
    [NoteTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [NoteTypes.LOAD_MORE_FAILURE]: loadFailure,

    [NoteTypes.REMOVE_REQUEST]: removeRequest,
    [NoteTypes.REMOVE_SUCCESS]: removeSuccess,
    [NoteTypes.REMOVE_FAILURE]: removeFailure
})

export default reducer
