import { action } from 'typesafe-actions'
import { IAxiosResponse, IPaginator, ISearch } from '../../root.types'
import { AssociatesTypes } from './types'
import { TypesUser } from '../../../application/models/users/users'
import Patient from '../../../application/models/users/patient'
import { UserTypes } from '../types'

export const resetList = () => action(AssociatesTypes.RESET_LIST)

export const changePaginatorAssociates = (userId: string, userType: TypesUser, paginator?: IPaginator) => [
    action(AssociatesTypes.CHANGE_PAGINATOR_ASSOCIATES, { userId, userType, paginator }),
    loadAssociatesRequest(userId, userType, paginator)
]

export const changeSearchPaginator = (userType: TypesUser, search: ISearch) => action(AssociatesTypes.CHANGE_SEARCH_PAGINATOR, {
    userType,
    search
})

export const changeRemoveDialog = (dialog: boolean, userId: string, id: string, userType?: TypesUser) =>
    action(AssociatesTypes.CHANGE_REMOVE_DIALOG, {
        dialog,
        userId,
        id,
        userType
    })

/* Actions for remove user */
export const removeAssociatedRequest = (id: string) => action(AssociatesTypes.REMOVE_ASSOCIATED_REQUEST, { id })

export const removeAssociatedSuccess = (userId: string) => action(
    AssociatesTypes.REMOVE_ASSOCIATED_SUCCESS,
    { userId }
)
// loadAssociatesRequest(userId, userType, paginator)


/* Actions for associates */
export const loadAssociatesRequest = (userId: string, userType: TypesUser, paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_ASSOCIATES_REQUEST, {
        userId,
        userType,
        paginator
    })

export const loadAssociatesSuccess = (userType: TypesUser, response: IAxiosResponse<any[]>) =>
    action(AssociatesTypes.LOAD_ASSOCIATES_SUCCESS, {
        userType,
        users: response.data,
        headers: response.headers
    })

export const loadAssociatesFailure = () => action(AssociatesTypes.LOAD_ASSOCIATES_FAILURE)

export const loadMoreAssociatesRequest = (userId: string, userType: TypesUser, paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_MORE_ASSOCIATES_REQUEST, { userId, userType, paginator }
)

export const loadMoreAssociatesSuccess = (userType: TypesUser, response: IAxiosResponse<any[]>) => action(
    AssociatesTypes.LOAD_MORE_ASSOCIATES_SUCCESS, {
        userType,
        users: response.data,
        headers: response.headers
    })

export const loadMoreAssociatesFailure = () => action(AssociatesTypes.LOAD_MORE_ASSOCIATES_FAILURE)

export const loadMultipleAvatarSuccess = (userId: string, avatar: any, userType?: TypesUser) => action(
    AssociatesTypes.LOAD_MULTIPLE_AVATAR_SUCCESS,
    { userId, avatar, userType }
)

export const loadMultipleAvatarFailure = (userId: any, userType?: TypesUser, paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_MULTIPLE_AVATAR_FAILURE,
    { userId, userType, paginator }
)


/* Actions for load caregivers or patients */
export const loadByHealthProfessionalId = (
    healthProfessionalId: string,
    type: TypesUser,
    paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_BY_HEALTH_PROFESSIONAL_ID,
    { healthProfessionalId, type, paginator }
)

export const loadMoreByHealthProfessionalId = (
    healthProfessionalId: string,
    type: TypesUser,
    paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_MORE_BY_HEALTH_PROFESSIONAL_ID,
    { healthProfessionalId, type, paginator }
)

/* Actions for remove user */
export const removeRequest = (id: string) => action(AssociatesTypes.REMOVE_REQUEST, { id })

export const removeSuccess = (userId: string) => action(AssociatesTypes.REMOVE_SUCCESS, { userId })

/* Actions to load patients associated with a caregiver */
export const loadByCaregiverId = (caregiverId: string, paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_BY_CAREGIVER_ID_REQUEST,
    { caregiverId, paginator, userType: TypesUser.PATIENT }
)

export const loadByCaregiverIdSuccess = (response: IAxiosResponse<Patient[]>) => action(
    AssociatesTypes.LOAD_BY_CAREGIVER_ID_SUCCESS, {
        users: response.data,
        headers: response.headers,
        userType: TypesUser.PATIENT
    })

export const loadByCaregiverIdFailure = () => action(
    AssociatesTypes.LOAD_BY_CAREGIVER_ID_FAILURE,
    { userType: TypesUser.PATIENT }
)

export const loadMoreByCaregiverId = (caregiverId: string, paginator?: IPaginator) => action(
    AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_REQUEST,
    { caregiverId, paginator, userType: TypesUser.PATIENT }
)

export const loadMoreByCaregiverIdSuccess = (response: IAxiosResponse<Patient[]>) => action(
    AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_SUCCESS, {
        users: response.data,
        headers: response.headers,
        userType: TypesUser.PATIENT
    })

export const loadMoreByCaregiverIdFailure = () => action(
    AssociatesTypes.LOAD_MORE_BY_CAREGIVER_ID_FAILURE,
    { userType: TypesUser.PATIENT }
)

export const caregiverChangePaginator = (caregiverId: string, paginator?: IPaginator) => [
    action(AssociatesTypes.CHANGE_PAGINATOR, { caregiverId, paginator }),
    loadByCaregiverId(caregiverId, paginator)
]

export const caregiverChangeSearchPaginator = (search: ISearch) => action(
    AssociatesTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeHPPaginator = (
    healthProfessionalId: string,
    type: TypesUser,
    paginator?: IPaginator) => [
    action(UserTypes.CHANGE_PAGINATOR, { userType: type, paginator }),
    loadByHealthProfessionalId(healthProfessionalId, type, paginator)
]

export const changeHPSearchPaginator = (search: ISearch) => action(UserTypes.CHANGE_SEARCH_PAGINATOR, { search })

export const associatePatient = (
    userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
    userId: string,
    patientId: string
) => action(
    AssociatesTypes.ASSOCIATE_PATIENT_REQUEST,
    { userType, userId, patientId }
)

export const associatePatientSuccess = (
    userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
    userId: string,
    patientId: string) => {
    const result: any[] = [
        action(AssociatesTypes.ASSOCIATE_PATIENT_SUCCESS, { userType, userId, patientId })
    ]
    if (userType === TypesUser.HEALTH_PROFESSIONAL) {
        result.push(loadAssociatesRequest(userId, TypesUser.PATIENT))
    }
    if (userType === TypesUser.CAREGIVER) {
        result.push(loadByCaregiverId(userId))
    }
    return result
}
export const associatePatientFailure = (patientId: string) => action(UserTypes.ASSOCIATE_PATIENT_FAILURE, { patientId })

export const disassociatePatient = (
    userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
    userId: string,
    patientId: string) => action(
    AssociatesTypes.DISASSOCIATE_PATIENT_REQUEST,
    { userType, userId, patientId }
)

export const disassociatePatientSuccess = (
    userType: TypesUser.HEALTH_PROFESSIONAL | TypesUser.CAREGIVER,
    userId: string,
    patientId: string) => {
    const result: any[] = [
        action(AssociatesTypes.DISASSOCIATE_PATIENT_SUCCESS, { userType, userId, patientId })
    ]
    if (userType === TypesUser.HEALTH_PROFESSIONAL) {
        result.push(loadAssociatesRequest(userId, TypesUser.PATIENT))
    }
    if (userType === TypesUser.CAREGIVER) {
        result.push(loadByCaregiverId(userId))
    }
    return result
}
export const disassociatePatientFailure = (patientId: string) => action(
    AssociatesTypes.DISASSOCIATE_PATIENT_FAILURE,
    { patientId }
)
