import { JsonUtils } from '../../utils/json.util'

export enum BodySide {
    LEFT = 'left',
    RIGHT = 'right'
}

export const MAP_BODY_SIDE = {
    [BodySide.LEFT]: 'PATIENTS.MEASUREMENTS.BODY_SIDE.LEFT',
    [BodySide.RIGHT]: 'PATIENTS.MEASUREMENTS.BODY_SIDE.RIGHT'
}

export default class Measurement {

    public static formatterValue(value: number | undefined, fractionDigits: number = 1): string {
        if (!value) {
            return ''
        }
        if (!Number.isInteger(value)) {
            return Number(value).toFixed(fractionDigits)
        }
        return `${value}`
    }

    private _id: string | undefined
    private _patient_id: string | undefined
    private _unit: string | undefined
    private _timestamp: string | undefined
    private _device_id: string | undefined
    private _type: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get unit(): string | undefined {
        return this._unit
    }

    set unit(value: string | undefined) {
        this._unit = value
    }

    get timestamp(): string | undefined {
        return this._timestamp
    }

    set timestamp(value: string | undefined) {
        this._timestamp = value
    }

    get device_id(): string | undefined {
        return this._device_id
    }

    set device_id(value: string | undefined) {
        this._device_id = value
    }

    get type(): string | undefined {
        return this._type
    }

    set type(value: string | undefined) {
        this._type = value
    }

    public fromJSON(json: any): Measurement {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        if (json.unit !== undefined) {
            this.unit = json.unit
        }

        if (json.timestamp !== undefined) {
            this.timestamp = json.timestamp
        }

        if (json.device_id !== undefined) {
            this.device_id = json.device_id
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined,
            unit: this.unit ? this.unit : undefined,
            timestamp: this.timestamp ? this.timestamp : undefined,
            device_id: this.device_id ? this.device_id : undefined,
            type: this.type ? this.type : undefined
        }
    }
}
