export default abstract class Entity {
    private _id: string | undefined
    private _created_at: string | undefined
    private _updated_at: string | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get created_at(): string | undefined {
        return this._created_at
    }

    set created_at(value: string | undefined) {
        this._created_at = value
    }

    get updated_at(): string | undefined {
        return this._updated_at
    }

    set updated_at(value: string | undefined) {
        this._updated_at = value
    }

    public abstract fromJSON(json: any): Entity

    public abstract toJSON(): any
}
