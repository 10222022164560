import IntradaySummary from './intraday.summary'
import IntradayDataSetItem from './intraday.data.set.item'
import { JsonUtils } from '../../../utils/json.util'

export default class IntradayTimeSeries {
    private _summary: IntradaySummary | undefined
    private _data_set: IntradayDataSetItem[] | undefined

    get summary(): IntradaySummary | undefined {
        return this._summary
    }

    set summary(value: IntradaySummary | undefined) {
        this._summary = value
    }

    get data_set(): IntradayDataSetItem[] | undefined {
        return this._data_set
    }

    set data_set(value: IntradayDataSetItem[] | undefined) {
        this._data_set = value
    }

    public fromJSON(json: any): IntradayTimeSeries {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.summary !== undefined) {
            this.summary = new IntradaySummary().fromJSON(json.summary)
        }

        if (json.data_set !== undefined) {
            this.data_set = json.data_set.map((item: IntradayDataSetItem) => new IntradayDataSetItem().fromJSON(item))
        }

        return this
    }

    public toJSON(): any {
        return {
            summary: this.summary ? this.summary.toJSON() : undefined,
            data_set: this.data_set ? this.data_set.map((item: IntradayDataSetItem) => item.toJSON()) : undefined
        }
    }
}
