import { Reducer } from 'redux'
import { DeviceTypes, IDeviceState } from './types'
import { createReducer } from 'reduxsauce'
import { failure, IActionType, IPaginator, ISearch, request, success } from '../../root.types'
import Device from '../../../application/models/device/device'
import DeviceCode from '../../../application/models/device/code'

export const INITIAL_STATE: IDeviceState = {
    create: {
        device: new Device(),
        loading: false,
        success: false,
        error: false,
        dialog: false
    },
    list: {
        devices: [],
        loading: false,
        error: false,
        success: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    authenticateCode: {
        loading: false,
        success: false,
        error: false,
        deviceCode: new DeviceCode(),
        dialog: false
    }
}

export const resetList = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const resetCreate = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        create: INITIAL_STATE.create
    }
}

/* Actions to find device  */

export const findRequest = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const findSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ device: Device }>) => {
    const { device } = action.payload
    return {
        ...state,
        create: success({ ...state.create, device })
    }
}

export const findFailure = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        create: failure(state.create)
    }
}

/* Actions to create device  */

export const changeDialogCreate = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ dialog: boolean, device: Device }>) => {
    const { device, dialog } = action.payload
    return {
        ...state,
        create: {
            ...state.create,
            dialog,
            device
        }
    }
}


export const createRequest = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, create: request(state.create) }
}

export const createSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ device: Device }>) => {
    const { device } = action.payload
    const listResult = [device]
        .concat(state.list.devices)
    return {
        ...state,
        create: INITIAL_STATE.create,
        list: success({
            ...state.list,
            devices: listResult
        })
    }
}

export const createFailure = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        create: failure(state.create)
    }
}

/* Actions to update device  */

export const updateSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ device: Device }>) => {
    const { device } = action.payload
    const listResult =
        state.list.devices
            .map((element: Device) => {
                if (element.id === device.id) {
                    return device
                }
                return element
            })
    return {
        ...state,
        create: success(state.create),
        list: success({
            ...state.list,
            devices: listResult
        })
    }
}

/* Actions to load device  */

export const changeSearchPaginator = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ search: ISearch }>) => {
    const { search } = action.payload
    return {
        ...state,
        list: {
            ...state.list,
            paginator: {
                ...state.list.paginator,
                search
            }
        }
    }
}

export const changeListPaginator = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return {
        ...state,
        list: { ...state.list, paginator }
    }
}

export const loadRequest = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ devices: Device[], headers: any }>) => {
    const { headers, devices } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({ ...state.list, paginator, devices })
    }
}

export const loadFailure = (state: IDeviceState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ devices: Device[], headers: any }>) => {
    const { headers, devices } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({
            ...state.list,
            paginator,
            devices: state.list.devices.concat(devices)
        })
    }
}

/* Actions to remove device  */

export const changeDialogRemove = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ dialog: true, ids: string[] }>) => {
    const { dialog, ids } = action.payload
    return {
        ...state,
        remove: {
            ...state.remove,
            dialog,
            ids
        }
    }
}

export const removeRequest = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        remove: request({ ...state.remove, dialog: true })
    }
}

export const removeSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ ids: string[] }>) => {
    const { ids } = action.payload
    const devices = state
        .list
        .devices
        ?.filter((device: Device) => !ids.some(removed => device?.id === removed))
    return {
        ...state,
        remove: success({ ...INITIAL_STATE.remove }),
        list: {
            ...state.list,
            devices
        }

    }
}

export const removeFailure = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        remove: failure(state.remove)
    }
}

export const generateCodeRequest = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        authenticateCode: request({ ...state.authenticateCode, dialog: true })
    }
}

export const generateCodeSuccess = (
    state: IDeviceState = INITIAL_STATE,
    action: IActionType<{ deviceCode: DeviceCode }>) => {
    const { deviceCode } = action.payload
    return {
        ...state,
        authenticateCode: success({ ...state.authenticateCode, deviceCode })
    }
}

export const generateCodeFailure = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        authenticateCode: failure(state.authenticateCode)
    }
}

export const generateCodeCloseDialog = (state: IDeviceState = INITIAL_STATE) => {
    return {
        ...state,
        authenticateCode: INITIAL_STATE.authenticateCode
    }
}

const reducer: Reducer<IDeviceState> = createReducer<IDeviceState>(INITIAL_STATE, {
    [DeviceTypes.RESET_LIST]: resetList,
    [DeviceTypes.RESET_CREATE]: resetCreate,

    [DeviceTypes.CHANGE_PAGINATOR]: changeListPaginator,
    [DeviceTypes.CHANGE_DIALOG_REMOVE]: changeDialogRemove,

    [DeviceTypes.CHANGE_DIALOG_CREATE]: changeDialogCreate,
    [DeviceTypes.CHANGE_SEARCH_PAGINATOR]: changeSearchPaginator,
    [DeviceTypes.CREATE_REQUEST]: createRequest,
    [DeviceTypes.CREATE_SUCCESS]: createSuccess,
    [DeviceTypes.CREATE_FAILURE]: createFailure,

    [DeviceTypes.FIND_REQUEST]: findRequest,
    [DeviceTypes.FIND_SUCCESS]: findSuccess,
    [DeviceTypes.FIND_FAILURE]: findFailure,

    [DeviceTypes.UPDATE_REQUEST]: createRequest,
    [DeviceTypes.UPDATE_SUCCESS]: updateSuccess,
    [DeviceTypes.UPDATE_FAILURE]: createFailure,

    [DeviceTypes.LOAD_ALL_REQUEST]: loadRequest,
    [DeviceTypes.LOAD_REQUEST]: loadRequest,
    [DeviceTypes.LOAD_SUCCESS]: loadSuccess,
    [DeviceTypes.LOAD_FAILURE]: loadFailure,

    [DeviceTypes.LOAD_MORE_REQUEST]: loadRequest,
    [DeviceTypes.LOAD_MORE_SUCCESS]: loadMoreSuccess,
    [DeviceTypes.LOAD_MORE_FAILURE]: loadFailure,

    [DeviceTypes.REMOVE_REQUEST]: removeRequest,
    [DeviceTypes.REMOVE_SUCCESS]: removeSuccess,
    [DeviceTypes.REMOVE_FAILURE]: removeFailure,

    [DeviceTypes.GENERATE_CODE_REQUEST]: generateCodeRequest,
    [DeviceTypes.GENERATE_CODE_SUCCESS]: generateCodeSuccess,
    [DeviceTypes.GENERATE_CODE_FAILURE]: generateCodeFailure,
    [DeviceTypes.GENERATE_CODE_CLOSE_DIALOG]: generateCodeCloseDialog
})

export default reducer
