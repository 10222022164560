import { JsonUtils } from '../../utils/json.util'

export default class Goal {
    // Optional
    private _steps: number | undefined
    private _calories: number | undefined
    private _distance: number | undefined
    private _active_minutes: number | undefined
    private _sleep: number | undefined

    get steps(): number | undefined {
        return this._steps
    }

    set steps(value: number | undefined) {
        this._steps = value
    }

    get calories(): number | undefined {
        return this._calories
    }

    set calories(value: number | undefined) {
        this._calories = value
    }

    get distance(): number | undefined {
        return this._distance
    }

    set distance(value: number | undefined) {
        this._distance = value
    }

    get active_minutes(): number | undefined {
        return this._active_minutes
    }

    set active_minutes(value: number | undefined) {
        this._active_minutes = value
    }

    get sleep(): number | undefined {
        return this._sleep
    }

    set sleep(value: number | undefined) {
        this._sleep = value
    }
    public fromJSON(json: any): Goal {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.steps !== undefined) {
            this.steps = json.steps
        }

        if (json.calories !== undefined) {
            this.calories = json.calories
        }

        if (json.distance !== undefined) {
            this.distance = json.distance
        }

        if (json.active_minutes !== undefined) {
            this.active_minutes = json.active_minutes
        }

        if (json.sleep !== undefined) {
            this.sleep = json.sleep
        }

        return this
    }

    public toJSON(): any {
        return {
            steps: this.steps ? this.steps : undefined,
            calories: this.calories ? this.calories : undefined,
            distance: this.distance ? this.distance : undefined,
            active_minutes: this.active_minutes ? this.active_minutes : undefined,
            sleep: this.sleep ? this.sleep : undefined,
        }
    }
}
