import { JsonUtils } from '../../../utils/json.util'
import { TimeSeriesInterval } from '../intraday/intraday.summary'

export class TimeSeriesIntervalFilter {
    private _date: string | undefined
    private _interval: TimeSeriesInterval | undefined

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        value && value?.length > 10 ?
            this._date = value?.substr(0, 10)
            : this._date = value
    }

    get interval(): TimeSeriesInterval | undefined {
        return this._interval
    }

    set interval(value: TimeSeriesInterval | undefined) {
        this._interval = value
    }

    public fromJSON(json: any): TimeSeriesIntervalFilter {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.interval !== undefined) {
            this.interval = json.interval
        }

        return this
    }

    public toJSON(): any {
        return {
            date: this.date ? this.date : undefined,
            interval: this.interval ? this.interval : undefined
        }
    }
}
