import React, { Component } from 'react'
import { Helmet } from 'react-helmet'
import { APPLICATION_CONFIG } from '../../material.theme'

interface IProps {
    readonly title: string
}

class PageTitle extends Component<IProps> {

    public render() {
        const { title } = this.props

        const helmetTitle: string = APPLICATION_CONFIG.abbreviation

        return <Helmet>
            <title>[{helmetTitle}] - {title}</title>
        </Helmet>
    }
}

export default PageTitle


