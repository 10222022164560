import { JsonUtils } from '../../utils/json.util'

export enum FatigueAnswer {
    NEVER = 'never',
    FEW_TIMES = 'few_times',
    MOSTLY = 'mostly',
    EVER = 'ever'
}

export default class Fatigue {
    private _effort_required: FatigueAnswer | undefined
    private _set_activities_aside: FatigueAnswer | undefined

    get effort_required(): FatigueAnswer | undefined {
        return this._effort_required
    }

    set effort_required(value: FatigueAnswer | undefined) {
        this._effort_required = value
    }

    get set_activities_aside(): FatigueAnswer | undefined {
        return this._set_activities_aside
    }

    set set_activities_aside(value: FatigueAnswer | undefined) {
        this._set_activities_aside = value
    }

    public fromJSON(json: any): Fatigue {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.effort_required !== undefined) {
            this.effort_required = json.effort_required
        }

        if (json.set_activities_aside !== undefined) {
            this.set_activities_aside = json.set_activities_aside
        }

        return this
    }

    public toJSON(): any {
        return {
            effort_required: this.effort_required ? this.effort_required : undefined,
            set_activities_aside: this.set_activities_aside ? this.set_activities_aside : undefined
        }
    }
}
