import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../../root.types'
import deviceService from '../../../../services/device'
import { SnackBarMessageType } from '../../../../components/snackbar'
import { open } from '../../snack.bar/actions'
import { DeviceTypes, IActionCreate, IActionFind, IActionGenerateCode, IActionLoad, IActionRemove } from './types'
import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess, generateCodeFailure, generateCodeSuccess,
    loadFailure,
    loadMoreFailure,
    loadMoreSuccess,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import Device from '../../../application/models/device/device'
import DeviceCode from '../../../application/models/device/code'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { device } = action.payload
        const response: Device = yield apply(
            deviceService,
            deviceService.create,
            [device]
        )
        yield put<any>(createSuccess(response))
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.DEVICES.CREATE.CREATED_SUCCESS')
        )
    } catch (e) {
        yield put(createFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { device } = action.payload
        const response: Device = yield apply(
            deviceService,
            deviceService.update,
            [device]
        )
        yield put<any>(updateSuccess(response))
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.DEVICES.UPDATE.UPDATE_SUCCESS')
        )
    } catch (e) {
        yield put(updateFailure())
    }
}

function* find(action: IActionType<IActionFind>) {
    try {
        const { patientId, deviceId } = action.payload
        const response: Device = yield apply(
            deviceService,
            deviceService.getByPatientAndDeviceId,
            [patientId, deviceId]
        )
        yield put<any>(findSuccess(response))
    } catch (e) {
        yield put(findFailure())
    }
}

function* load(action: IActionType<IActionLoad>) {
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<Device[]> = yield apply(
            deviceService,
            deviceService.getAllByPatientId,
            [patientId, paginator]
        )
        yield put(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<Device[]> = yield apply(
            deviceService,
            deviceService.getAllByPatientId,
            [patientId, paginator]
        )
        yield put(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { patientId, ids } = action.payload
        for (const activityId of ids) {
            yield apply(
                deviceService,
                deviceService.remove,
                [patientId, activityId]
            )
        }
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                'PATIENTS.DEVICES.REMOVE.REMOVED_SUCCESS')
        )
        yield put(removeSuccess(ids))
    } catch (e) {
        yield put(removeFailure())
    }
}

function* loadAll(action: IActionType<IActionLoad>) {
    try {
        const { patientId, paginator } = action.payload
        const response: IAxiosResponse<Device[]> = yield apply(
            deviceService,
            deviceService.getAll,
            [patientId, paginator]
        )
        yield put(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* generateCode(action: IActionType<IActionGenerateCode>) {
    try {
        const { deviceId } = action.payload
        const response: DeviceCode = yield apply(
            deviceService,
            deviceService.generateCode,
            [deviceId]
        )
        yield put(generateCodeSuccess(response))
    } catch (e) {
        yield put(generateCodeFailure())
    }
}

export default function* patientSaga() {
    return yield all([
        takeLatest(DeviceTypes.CREATE_REQUEST, create),
        takeLatest(DeviceTypes.UPDATE_REQUEST, update),
        takeLatest(DeviceTypes.FIND_REQUEST, find),
        takeLatest(DeviceTypes.LOAD_REQUEST, load),
        takeLatest(DeviceTypes.LOAD_ALL_REQUEST, loadAll),
        takeLatest(DeviceTypes.LOAD_MORE_REQUEST, loadMore),
        takeLatest(DeviceTypes.REMOVE_REQUEST, remove),
        takeLatest(DeviceTypes.GENERATE_CODE_REQUEST, generateCode)
    ])
}
