import { JsonUtils } from '../../utils/json.util'

export default abstract class Activity {
    private _id: string | undefined
    private _patient_id: string | undefined
    private _start_time: string | undefined
    private _end_time: string | undefined
    private _duration: number | undefined

    get id(): string | undefined {
        return this._id
    }

    set id(value: string | undefined) {
        this._id = value
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get start_time(): string | undefined {
        return this._start_time
    }

    set start_time(value: string | undefined) {
        this._start_time = value
    }

    get end_time(): string | undefined {
        return this._end_time
    }

    set end_time(value: string | undefined) {
        this._end_time = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    public fromJSON(json: any): Activity {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.id !== undefined) {
            this.id = json.id
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        if (json.start_time !== undefined) {
            this.start_time = json.start_time
        }

        if (json.end_time !== undefined) {
            this.end_time = json.end_time
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        return this
    }

    public toJSON(): any {
        return {
            id: this.id ? this.id : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined,
            start_time: this.start_time ? this.start_time : undefined,
            end_time: this.end_time ? this.end_time : undefined,
            duration: this.duration ? this.duration : undefined
        }
    }
}
