import { TypesUser } from '../models/users/users'
import Admin from '../models/users/admin'
import HealthProfessional from '../models/users/health.professional'
import Caregiver from '../models/users/caregiver'
import Patient from '../models/users/patient'

export class FactoryUser {

    public static build(values: any, type: TypesUser): any {
        switch (type) {
            case TypesUser.ADMIN:
                return new Admin().fromJSON(values)
            case TypesUser.HEALTH_PROFESSIONAL:
                return new HealthProfessional().fromJSON(values)
            case TypesUser.CAREGIVER:
                return new Caregiver().fromJSON(values)
            case TypesUser.PATIENT:
                return new Patient().fromJSON(values)
            default:
                return values
        }
    }
}
