import { Reducer } from 'redux'
import { createReducer } from 'reduxsauce'

import Sleep from '../../../application/models/activity/sleep/sleep'
import { failure, IActionType, IPaginator, request, success } from '../../root.types'
import { ISleepState, SleepTypes } from './types'
import IntradayHeartRate from '../../../application/models/time.series/intraday/heart.rate/intraday.heart.rate'

export const INITIAL_STATE: ISleepState = {
    details: {
        sleep: new Sleep(),
        loading: false,
        success: false,
        error: false,
        heartRate: {
            heartRate: new IntradayHeartRate(),
            loading: false,
            success: false,
            error: false
        }
    },
    list: {
        sleeps: [],
        loading: false,
        success: false,
        error: false,
        paginator: {
            first: 0,
            rows: 10,
            page: 0,
            pageCount: 0,
            totalRecords: 0,
            search: {
                key: '',
                value: ''
            }
        }
    },
    remove: {
        ids: [],
        dialog: false,
        loading: false,
        success: false,
        error: false
    },
    chart: {
        sleeps: [],
        loading: false,
        success: false,
        error: false
    }
}

/* Reducers for Sleep */

export const resetList = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        list: INITIAL_STATE.list
    }
}

export const resetDetails = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        details: INITIAL_STATE.details
    }
}


export const findSleepRequest = (state: ISleepState = INITIAL_STATE) => {
    return { ...state, details: request(state.details) }
}

export const findSleepSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ sleep: Sleep }>) => {
    const { sleep } = action.payload
    return {
        ...state,
        details: success({ ...state.details, sleep })
    }
}

export const findSleepFailure = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        details: failure(state.details)
    }
}

export const changeSleepPaginator = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ paginator: IPaginator }>) => {
    const { paginator } = action.payload
    return {
        ...state,
        list: { ...state.list, paginator }
    }
}

export const loadSleepRequest = (state: ISleepState = INITIAL_STATE) => {
    return { ...state, list: request(state.list) }
}

export const loadSleepSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ sleeps: Sleep[], headers: any }>) => {
    const { headers, sleeps } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({ ...state.list, paginator, sleeps })
    }
}

export const loadSleepFailure = (state: ISleepState = INITIAL_STATE) => {
    return { ...state, list: failure(state.list) }
}

export const loadMoreSleepSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ sleeps: Sleep[], headers: any }>) => {
    const { headers, sleeps } = action.payload
    const paginator = {
        ...state.list.paginator,
        totalRecords: parseInt(headers['x-total-count'], 10)
    }
    return {
        ...state,
        list: success({
            ...state.list,
            paginator,
            sleeps: state.list.sleeps.concat(sleeps)
        })
    }
}

export const changeDialogRemoveSleep = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ dialog: true, ids: string[] }>) => {
    const { dialog, ids } = action.payload
    return {
        ...state,
        remove: {
            ...state.remove,
            dialog,
            ids
        }
    }
}

export const removeSleepRequest = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        remove: request({ ...state.remove, dialog: true })
    }
}

export const removeSleepSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ ids: string[] }>) => {
    const { ids } = action.payload
    const sleeps = state
        .list
        .sleeps
        ?.filter((sleep: Sleep) => !ids.some(removed => sleep?.id === removed))
    return {
        ...state,
        remove: success({ ...INITIAL_STATE.remove }),
        list: {
            ...state.list,
            sleeps
        }
    }
}

export const removeSleepFailure = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        remove: failure(state.remove)
    }
}


export const heartRateRequest = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: request(state.details.heartRate)
        }
    }
}

export const heartRateSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ heartRate: IntradayHeartRate }>) => {
    const { heartRate } = action.payload
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: success({ ...state.details.heartRate, heartRate })
        }
    }
}

export const heartRateFailure = (state: ISleepState = INITIAL_STATE) => {
    return {
        ...state,
        details: {
            ...state.details,
            heartRate: failure(state.details.heartRate)
        }
    }
}

export const chartLoadSleepRequest = (state: ISleepState = INITIAL_STATE) => {
    return { ...state, chart: request(state.chart) }
}

export const chartLoadSleepSuccess = (
    state: ISleepState = INITIAL_STATE,
    action: IActionType<{ sleeps: Sleep[]}>) => {
    const { sleeps } = action.payload
    return {
        ...state,
        chart: success({ ...state.chart, sleeps })
    }
}

export const chartLoadSleepFailure = (state: ISleepState = INITIAL_STATE) => {
    return { ...state, chart: failure(state.chart) }
}

const reducer: Reducer<ISleepState> = createReducer<ISleepState>(INITIAL_STATE, {
    [SleepTypes.RESET_LIST]: resetList,
    [SleepTypes.RESET_DETAILS]: resetDetails,

    [SleepTypes.FIND_REQUEST]: findSleepRequest,
    [SleepTypes.FIND_SUCCESS]: findSleepSuccess,
    [SleepTypes.FIND_FAILURE]: findSleepFailure,

    [SleepTypes.CHANGE_SLEEP_PAGINATOR]: changeSleepPaginator,
    [SleepTypes.LOAD_SLEEP_REQUEST]: loadSleepRequest,
    [SleepTypes.LOAD_SLEEP_SUCCESS]: loadSleepSuccess,
    [SleepTypes.LOAD_SLEEP_FAILURE]: loadSleepFailure,

    [SleepTypes.LOAD_MORE_SLEEP_REQUEST]: loadSleepRequest,
    [SleepTypes.LOAD_MORE_SLEEP_SUCCESS]: loadMoreSleepSuccess,
    [SleepTypes.LOAD_MORE_SLEEP_FAILURE]: loadSleepFailure,

    [SleepTypes.CHANGE_DIALOG_REMOVE_SLEEP]: changeDialogRemoveSleep,
    [SleepTypes.REMOVE_SLEEP_REQUEST]: removeSleepRequest,
    [SleepTypes.REMOVE_SLEEP_SUCCESS]: removeSleepSuccess,
    [SleepTypes.REMOVE_SLEEP_FAILURE]: removeSleepFailure,

    [SleepTypes.LOAD_HEART_RATE_REQUEST]: heartRateRequest,
    [SleepTypes.LOAD_HEART_RATE_SUCCESS]: heartRateSuccess,
    [SleepTypes.LOAD_HEART_RATE_FAILURE]: heartRateFailure,

    [SleepTypes.CHART_SLEEP_REQUEST]: chartLoadSleepRequest,
    [SleepTypes.CHART_SLEEP_SUCCESS]: chartLoadSleepSuccess,
    [SleepTypes.CHART_SLEEP_FAILURE]: chartLoadSleepFailure
})

export default reducer
