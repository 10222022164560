import { action } from 'typesafe-actions'
import { DeviceTypes } from './types'
import { IAxiosResponse, IPaginator, ISearch } from '../../root.types'
import Device from '../../../application/models/device/device'
import DeviceCode from '../../../application/models/device/code'

export const resetList = () => action(DeviceTypes.RESET_LIST)

export const resetCreate = () => action(DeviceTypes.RESET_CREATE)

/* Actions to create a device */

export const changeDialogCreate = (dialog: boolean, device: Device) => action(
    DeviceTypes.CHANGE_DIALOG_CREATE,
    { dialog, device }
)

export const createRequest = (device: Device) => action(DeviceTypes.CREATE_REQUEST, { device })

export const createSuccess = (device: Device) => [
    action(DeviceTypes.CREATE_SUCCESS, { device }),
    generateCodeRequest(`${device?.id}`)
]

export const createFailure = () => action(DeviceTypes.CREATE_FAILURE)

/* Actions to update a device */

export const updateRequest = (device: Device) => action(DeviceTypes.UPDATE_REQUEST, { device })

export const updateSuccess = (device: Device) => action(DeviceTypes.UPDATE_SUCCESS, { device })

export const updateFailure = () => action(DeviceTypes.UPDATE_FAILURE)

/* Actions to find a device */

export const findRequest = (patientId: string, deviceId: string) => action(
    DeviceTypes.FIND_REQUEST,
    { patientId, deviceId }
)

export const findSuccess = (device: Device) => action(DeviceTypes.FIND_SUCCESS, { device })

export const findFailure = () => action(DeviceTypes.FIND_FAILURE)

/* Actions for device list */

export const changeSearchPaginator = (search: ISearch) => action(
    DeviceTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeListPaginator = (patientId: string, paginator?: IPaginator) => [
    action(DeviceTypes.CHANGE_PAGINATOR, { patientId, paginator }),
    loadRequest(patientId, paginator)
]

export const loadAllRequest = (patientId: string) => action(DeviceTypes.LOAD_ALL_REQUEST, { patientId })

export const loadRequest = (patientId: string, paginator?: IPaginator) => action(
    DeviceTypes.LOAD_REQUEST,
    { patientId, paginator }
)

export const loadSuccess = (response: IAxiosResponse<Device[]>) => action(
    DeviceTypes.LOAD_SUCCESS,
    {
        devices: response.data,
        headers: response.headers
    }
)

export const loadFailure = () => action(DeviceTypes.LOAD_FAILURE)

export const loadMoreRequest = (patientId: string, paginator?: IPaginator) => action(
    DeviceTypes.LOAD_MORE_REQUEST,
    { patientId, paginator }
)

export const loadMoreSuccess = (response: IAxiosResponse<Device[]>) => action(
    DeviceTypes.LOAD_MORE_SUCCESS,
    {
        devices: response.data,
        headers: response.headers
    }
)

export const loadMoreFailure = () => action(DeviceTypes.LOAD_MORE_FAILURE)

/* Actions for device remove */

export const changeDialogRemove = (dialog: boolean, ids: string[]) => action(
    DeviceTypes.CHANGE_DIALOG_REMOVE,
    {
        dialog,
        ids
    }
)

export const removeRequest = (patientId: string, ids: string[]) => action(
    DeviceTypes.REMOVE_REQUEST,
    { patientId, ids }
)

export const removeSuccess = (ids: string[]) => action(
    DeviceTypes.REMOVE_SUCCESS,
    { ids }
)

export const removeFailure = () => action(DeviceTypes.REMOVE_FAILURE)

export const generateCodeRequest = (deviceId: string) => action(
    DeviceTypes.GENERATE_CODE_REQUEST,
    { deviceId }
)

export const generateCodeSuccess = (deviceCode: DeviceCode) => action(
    DeviceTypes.GENERATE_CODE_SUCCESS,
    { deviceCode }
)

export const generateCodeFailure = () => action(DeviceTypes.GENERATE_CODE_FAILURE)

export const generateCodeCloseDialog = () => action(DeviceTypes.GENERATE_CODE_CLOSE_DIALOG)
