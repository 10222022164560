import { JsonUtils } from '../../utils/json.util'
import Entity from '../entity'
import { TypeMeasurement } from './measurements.last'

export default class HeartRateVariability extends Entity {
    private _fail_sync: boolean | undefined
    private _patient_id: string | undefined
    private _date: string | undefined
    private _max: number | undefined
    private _max_hr: number | undefined
    private _mean: number | undefined
    private _mean_hr: number | undefined
    private _min: number | undefined
    private _min_hr: number | undefined
    private _nni_20: number | undefined
    private _nni_50: number | undefined
    private _pnni_50: number | undefined
    private _rmssd: number | undefined
    private _sdann: number | undefined
    private _sdnn: number | undefined
    private _sdnni: number | undefined
    private _sdsd: number | undefined
    private _std: number | undefined
    private _std_hr: number | undefined
    private _triangular_index: number | undefined
    private readonly _type: TypeMeasurement | undefined

    constructor() {
        super()
        this._type = TypeMeasurement.HEART_RATE_VARIABILITY
    }

    get patient_id(): string | undefined {
        return this._patient_id
    }

    set patient_id(value: string | undefined) {
        this._patient_id = value
    }

    get date(): string | undefined {
        return this._date
    }

    set date(value: string | undefined) {
        this._date = value
    }

    get max(): number | undefined {
        return this._max
    }

    set max(value: number | undefined) {
        this._max = value
    }

    get max_hr(): number | undefined {
        return this._max_hr
    }

    set max_hr(value: number | undefined) {
        this._max_hr = value
    }

    get mean(): number | undefined {
        return this._mean
    }

    set mean(value: number | undefined) {
        this._mean = value
    }

    get mean_hr(): number | undefined {
        return this._mean_hr
    }

    set mean_hr(value: number | undefined) {
        this._mean_hr = value
    }

    get min(): number | undefined {
        return this._min
    }

    set min(value: number | undefined) {
        this._min = value
    }

    get min_hr(): number | undefined {
        return this._min_hr
    }

    set min_hr(value: number | undefined) {
        this._min_hr = value
    }

    get nni_20(): number | undefined {
        return this._nni_20
    }

    set nni_20(value: number | undefined) {
        this._nni_20 = value
    }

    get nni_50(): number | undefined {
        return this._nni_50
    }

    set nni_50(value: number | undefined) {
        this._nni_50 = value
    }

    get pnni_50(): number | undefined {
        return this._pnni_50
    }

    set pnni_50(value: number | undefined) {
        this._pnni_50 = value
    }

    get rmssd(): number | undefined {
        return this._rmssd
    }

    set rmssd(value: number | undefined) {
        this._rmssd = value
    }

    get sdann(): number | undefined {
        return this._sdann
    }

    set sdann(value: number | undefined) {
        this._sdann = value
    }

    get sdnn(): number | undefined {
        return this._sdnn
    }

    set sdnn(value: number | undefined) {
        this._sdnn = value
    }

    get sdnni(): number | undefined {
        return this._sdnni
    }

    set sdnni(value: number | undefined) {
        this._sdnni = value
    }

    get sdsd(): number | undefined {
        return this._sdsd
    }

    set sdsd(value: number | undefined) {
        this._sdsd = value
    }

    get std(): number | undefined {
        return this._std
    }

    set std(value: number | undefined) {
        this._std = value
    }

    get std_hr(): number | undefined {
        return this._std_hr
    }

    set std_hr(value: number | undefined) {
        this._std_hr = value
    }

    get triangular_index(): number | undefined {
        return this._triangular_index
    }

    set triangular_index(value: number | undefined) {
        this._triangular_index = value
    }

    get fail_sync(): boolean | undefined {
        return this._fail_sync
    }

    get type(): TypeMeasurement | undefined {
        return this._type
    }

    public fromJSON(json: any): HeartRateVariability {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.created_at !== undefined) {
            this.created_at = json.created_at
        }

        if (json.updated_at !== undefined) {
            this.updated_at = json.updated_at
        }

        if (json.patient_id !== undefined) {
            this.patient_id = json.patient_id
        }

        if (json.date !== undefined) {
            this.date = json.date
        }

        if (json.max !== undefined) {
            this.max = json.max
        }

        if (json.max_hr !== undefined) {
            this.max_hr = json.max_hr
        }

        if (json.mean !== undefined) {
            this.mean = json.mean
        }

        if (json.mean_hr !== undefined) {
            this.mean_hr = json.mean_hr
        }

        if (json.min !== undefined) {
            this.min = json.min
        }

        if (json.min_hr !== undefined) {
            this.min_hr = json.min_hr
        }

        if (json.nni_20 !== undefined) {
            this.nni_20 = json.nni_20
        }

        if (json.nni_50 !== undefined) {
            this.nni_50 = json.nni_50
        }

        if (json.pnni_50 !== undefined) {
            this.pnni_50 = json.pnni_50
        }

        if (json.rmssd !== undefined) {
            this.rmssd = json.rmssd
        }

        if (json.sdann !== undefined) {
            this.sdann = json.sdann
        }

        if (json.sdnn !== undefined) {
            this.sdnn = json.sdnn
        }

        if (json.sdnni !== undefined) {
            this.sdnni = json.sdnni
        }

        if (json.sdsd !== undefined) {
            this.sdsd = json.sdsd
        }

        if (json.std !== undefined) {
            this.std = json.std
        }

        if (json.std_hr !== undefined) {
            this.std_hr = json.std_hr
        }

        if (json.triangular_index !== undefined) {
            this.triangular_index = json.triangular_index
        }


        return this
    }

    public toJSON(): any {
        return {
            created_at: this.created_at ? this.created_at : undefined,
            updated_at: this.updated_at ? this.updated_at : undefined,
            patient_id: this.patient_id ? this.patient_id : undefined,
            date: this.date ? this.date : undefined,
            max: this.max ? this.max : undefined,
            max_hr: this.max_hr ? this.max_hr : undefined,
            mean: this.mean ? this.mean : undefined,
            mean_hr: this.mean_hr ? this.mean_hr : undefined,
            min: this.min ? this.min : undefined,
            min_hr: this.min_hr ? this.min_hr : undefined,
            nni_20: this.nni_20 ? this.nni_20 : undefined,
            nni_50: this.nni_50 ? this.nni_50 : undefined,
            pnni_50: this.pnni_50 ? this.pnni_50 : undefined,
            rmssd: this.rmssd ? this.rmssd : undefined,
            sdann: this.sdann ? this.sdann : undefined,
            sdnn: this.sdnn ? this.sdnn : undefined,
            sdnni: this.sdnni ? this.sdnni : undefined,
            sdsd: this.sdsd ? this.sdsd : undefined,
            std: this.std ? this.std : undefined,
            std_hr: this.std_hr ? this.std_hr : undefined,
            triangular_index: this.triangular_index ? this.triangular_index : undefined
        }
    }

    public isEmpty(): boolean {
        return !this.max &&
            !this.max_hr &&
            !this.mean &&
            !this.mean_hr &&
            !this.min &&
            !this.min_hr &&
            !this.nni_20 &&
            !this.nni_50 &&
            !this.pnni_50 &&
            !this.rmssd &&
            !this.sdann &&
            !this.sdnn &&
            !this.sdnni &&
            !this.sdsd &&
            !this.std &&
            !this.std_hr &&
            !this.triangular_index
    }

    public withFail(): HeartRateVariability {
        this._fail_sync = true
        return this
    }
}
