import Measurement from './measurement'
import { TypeMeasurement } from './measurements.last'

export default class Height extends Measurement {

    private _value: number | undefined

    constructor() {
        super()
        this.type = TypeMeasurement.HEIGHT
    }

    get value(): number | undefined {
        return this._value
    }

    set value(value: number | undefined) {
        this._value = value
    }

    public fromJSON(json: any): Height {

        super.fromJSON(json)

        if (json.value !== undefined) {
            const value: number = json.value
            this.value = value < 3 ? value : (value / 100)
        }

        this.unit = 'm'

        return this
    }

    public toJSON(): any {
        const value: number | undefined = this?.value && this.value < 3 ? this.value * 100 : this.value
        return {
            ...super.toJSON(),
            value,
            unit: 'cm'
        }
    }
}
