import { all, apply, put, takeLatest } from 'redux-saga/effects'

import { IActionType, IAxiosResponse } from '../../root.types'
import noteService from '../../../../services/note'
import { SnackBarMessageType } from '../../../../components/snackbar'
import { open } from '../../snack.bar/actions'
import { IActionCreate, IActionFind, IActionLoad, IActionRemove, NoteTypes } from './types'
import {
    createFailure,
    createSuccess,
    findFailure,
    findSuccess,
    loadFailure,
    loadMoreFailure,
    loadMoreSuccess,
    loadSuccess,
    removeFailure,
    removeSuccess,
    updateFailure,
    updateSuccess
} from './actions'
import Note from '../../../application/models/note/note'
import { MAP_NOTE_TYPE_LABEL } from '../../../../containers/users/patients/notes/create'

function* create(action: IActionType<IActionCreate>) {
    try {
        const { note } = action.payload
        const response: Note = yield apply(
            noteService,
            noteService.create,
            [note]
        )
        yield put(createSuccess(response))
        const radical: string = MAP_NOTE_TYPE_LABEL[`${note?.type}`]
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                `PATIENTS.${radical}.CREATE.CREATED_SUCCESS`)
        )
    } catch (e) {
        yield put(createFailure())
    }
}

function* update(action: IActionType<IActionCreate>) {
    try {
        const { note } = action.payload
        const radical: string = MAP_NOTE_TYPE_LABEL[`${note?.type}`]
        const response: Note = yield apply(
            noteService,
            noteService.update,
            [note]
        )
        yield put<any>(updateSuccess(response))
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                `PATIENTS.${radical}.UPDATE.UPDATE_SUCCESS`)
        )
    } catch (e) {
        yield put(updateFailure())
    }
}

function* find(action: IActionType<IActionFind>) {
    try {
        const { patientId, type, noteId } = action.payload
        const diagnosis: Note = yield apply(
            noteService,
            noteService.getById,
            [patientId, type, noteId]
        )
        yield put<any>(findSuccess(diagnosis))
    } catch (e) {
        yield put(findFailure())
    }
}

function* load(action: IActionType<IActionLoad>) {
    try {
        const { patientId, type, paginator, filter } = action.payload
        const response: IAxiosResponse<Note[]> = yield apply(
            noteService,
            noteService.getAll,
            [patientId, type, paginator, filter]
        )
        yield put(loadSuccess(response))
    } catch (e) {
        yield put(loadFailure())
    }
}

function* loadMore(action: IActionType<IActionLoad>) {
    try {
        const { patientId, type, paginator, filter } = action.payload
        const response: IAxiosResponse<Note[]> = yield apply(
            noteService,
            noteService.getAll,
            [patientId, type, paginator, filter]
        )
        yield put(loadMoreSuccess(response))
    } catch (e) {
        yield put(loadMoreFailure())
    }
}

function* remove(action: IActionType<IActionRemove>) {
    try {
        const { patientId, type, ids } = action.payload
        for (const activityId of ids) {
            yield apply(
                noteService,
                noteService.remove,
                [patientId, type, activityId]
            )
        }
        const radical: string = MAP_NOTE_TYPE_LABEL[type]
        yield put(
            open(
                SnackBarMessageType.SUCCESS,
                '',
                `PATIENTS.${radical}.REMOVE.REMOVED_SUCCESS`)
        )
        yield put(removeSuccess(ids))
    } catch (e) {
        yield put(removeFailure())
    }
}

export default function* patientSaga() {
    return yield all([
        takeLatest(NoteTypes.CREATE_REQUEST, create),
        takeLatest(NoteTypes.UPDATE_REQUEST, update),
        takeLatest(NoteTypes.FIND_REQUEST, find),
        takeLatest(NoteTypes.LOAD_REQUEST, load),
        takeLatest(NoteTypes.LOAD_MORE_REQUEST, loadMore),
        takeLatest(NoteTypes.REMOVE_REQUEST, remove)
    ])
}
