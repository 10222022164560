import axiosInstance from './axios'
import { IAxiosResponse, IPaginator } from '../store/ducks/root.types'
import Device from '../store/application/models/device/device'
import { AxiosResponse } from 'axios'
import DeviceCode from '../store/application/models/device/code'

class DeviceService {

    constructor(private apiVersion: string = 'v1') {
    }

    public create(device: Device): Promise<Device> {
        return axiosInstance
            .post(`${this.apiVersion}/patients/${device.patient_id}/devices`, device.toJSON())
            .then((response: AxiosResponse<any>) => new Device().fromJSON(response.data))
    }

    public getAllByPatientId(patientId: string, paginator?: IPaginator): Promise<IAxiosResponse<Device[]>> {
        const params: URLSearchParams = this.buildParams(paginator)
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/devices`, { params })
            .then((response) => {
                const devices: Device[] = response
                    .data
                    .map(device => new Device().fromJSON(device))
                return {
                    data: devices,
                    headers: response.headers
                }
            })
    }

    public getByPatientAndDeviceId(patientId: string, deviceId: string): Promise<Device> {
        return axiosInstance
            .get(`${this.apiVersion}/patients/${patientId}/devices/${deviceId}`)
            .then((response: AxiosResponse<any>) => new Device().fromJSON(response.data))
    }

    public update(device: Device): Promise<Device> {
        return axiosInstance
            .patch(`${this.apiVersion}/patients/${device.patient_id}/devices/${device.id}`, device.toJSON())
            .then((response: AxiosResponse<any>) => new Device().fromJSON(response.data))
    }

    public remove(patientId: string, deviceId: string): Promise<void> {
        return axiosInstance.delete(`${this.apiVersion}/patients/${patientId}/devices/${deviceId}`)
    }

    public generateCode(deviceId: string): Promise<DeviceCode> {
        return axiosInstance
            .post(`${this.apiVersion}/devices/${deviceId}/generatecode`)
            .then((response: AxiosResponse<any>) => new DeviceCode().fromJSON(response.data))
    }

    public async getAll(patientId: string, paginator?: IPaginator): Promise<IAxiosResponse<Device[]>> {
        let fitBitResponse: IAxiosResponse<Device[]>
        let internalResponse: IAxiosResponse<Device[]>
        const errorResponse: IAxiosResponse<any> = {
            data: [],
            headers: { 'x-total-count': 0 }
        }

        try {
            fitBitResponse = await this.getAllFromFitBit(patientId, paginator)
        } catch (e) {
            fitBitResponse = errorResponse
        }

        try {
            internalResponse = await this.getAllByPatientId(patientId, paginator)
        } catch (e) {
            internalResponse = errorResponse
        }

        const xTotalCount = parseInt(fitBitResponse.headers['x-total-count'], 10) +
            parseInt(internalResponse.headers['x-total-count'], 10)

        return {
            data: fitBitResponse.data.concat(internalResponse.data),
            headers: { ...fitBitResponse.headers, 'x-total-count': xTotalCount }
        }
    }

    public getAllFromFitBit(patientId: string, paginator?: IPaginator): Promise<IAxiosResponse<Device[]>> {
        const params: URLSearchParams = this.buildParams(paginator)

        return axiosInstance
            .get(`${this.apiVersion}/users/${patientId}/fitbit/devices`, { params })
            .then((response) => {
                const devices: Device[] = response
                    .data
                    .map(device => new Device().fromJSON(device))
                return {
                    data: devices,
                    headers: response.headers
                }
            })
    }

    private buildParams(paginator?: IPaginator): URLSearchParams {
        const params = new URLSearchParams()
        if (paginator) {
            const hasSearch: boolean = !!(paginator?.search?.key && paginator?.search?.value)

            if (paginator.page && !hasSearch) {
                params.append('page', String(paginator.page + 1))
            }

            if (paginator.rows) {
                params.append('limit', String(paginator.rows))
            }

            if (hasSearch) {
                params.append(paginator?.search?.key, `*${paginator?.search?.value}*`)
            }
        }
        return params
    }
}

export default new DeviceService()
