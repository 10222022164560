import { action } from 'typesafe-actions'
import { NoteTypes } from './types'
import { IAxiosResponse, IPaginator, ISearch } from '../../root.types'
import { TimeSeriesSimpleFilter } from '../../../application/models/time.series/filters/simple.filter'
import Note from '../../../application/models/note/note'
import { NoteType } from '../../../application/utils/note.type'

export const resetList = () => action(NoteTypes.RESET_LIST)

export const resetCreate = () => action(NoteTypes.RESET_CREATE)

/* Actions to create a note */

export const changeDialogCreate = (dialog: boolean, note: Note) => {
    return action(
        NoteTypes.CHANGE_DIALOG_CREATE,
        { dialog, note }
    )
}

export const createRequest = (note: Note) => action(NoteTypes.CREATE_REQUEST, { note })

export const createSuccess = (note: Note) => action(NoteTypes.CREATE_SUCCESS, { note })

export const createFailure = () => action(NoteTypes.CREATE_FAILURE)

/* Actions to update a note */

export const updateRequest = (note: Note) => action(NoteTypes.UPDATE_REQUEST, { note })

export const updateSuccess = (note: Note) => action(NoteTypes.UPDATE_SUCCESS, { note })

export const updateFailure = () => action(NoteTypes.UPDATE_FAILURE)

/* Actions to find a note */

export const findRequest = (patientId: string, type: NoteType, noteId: string) => action(
    NoteTypes.FIND_REQUEST,
    { patientId, type, noteId }
)

export const findSuccess = (note: Note) => action(NoteTypes.FIND_SUCCESS, { note })

export const findFailure = () => action(NoteTypes.FIND_FAILURE)

/* Actions for note list */

export const changeSearchPaginator = (search: ISearch) => action(
    NoteTypes.CHANGE_SEARCH_PAGINATOR,
    { search }
)

export const changeListPaginator = (
    patientId: string,
    type: NoteType,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter
) => [
    action(NoteTypes.CHANGE_PAGINATOR, { patientId, paginator }),
    loadRequest(patientId, type, paginator, filter)
]

export const loadRequest = (
    patientId: string,
    type: NoteType,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter) => action(
    NoteTypes.LOAD_REQUEST,
    { patientId, type, paginator, filter }
)

export const loadSuccess = (response: IAxiosResponse<Note[]>) => action(
    NoteTypes.LOAD_SUCCESS,
    {
        data: response.data,
        headers: response.headers
    }
)

export const loadFailure = () => action(NoteTypes.LOAD_FAILURE)

export const loadMoreRequest = (
    patientId: string,
    type: NoteType,
    paginator?: IPaginator,
    filter?: TimeSeriesSimpleFilter
) => action(
    NoteTypes.LOAD_MORE_REQUEST,
    { patientId, type, paginator, filter }
)

export const loadMoreSuccess = (response: IAxiosResponse<Note[]>) => action(
    NoteTypes.LOAD_MORE_SUCCESS,
    {
        data: response.data,
        headers: response.headers
    }
)

export const loadMoreFailure = () => action(NoteTypes.LOAD_MORE_FAILURE)

/* Actions for note remove */

export const changeDialogRemove = (dialog: boolean, ids: string[]) => action(
    NoteTypes.CHANGE_DIALOG_REMOVE,
    {
        dialog,
        ids
    }
)

export const removeRequest = (patientId: string, type: NoteType, ids: string[]) => action(
    NoteTypes.REMOVE_REQUEST,
    { patientId, type, ids }
)

export const removeSuccess = (ids: string[]) => action(
    NoteTypes.REMOVE_SUCCESS,
    { ids }
)

export const removeFailure = () => action(NoteTypes.REMOVE_FAILURE)

