import Address from './address'
import User, { TypesUser } from './users'

export default class Caregiver extends User {
    // Required
    private _address: Address | undefined
    // Readonly
    private _total_patients: number | undefined
    private _health_professional_id: string | undefined

    constructor() {
        super()
        this.type = TypesUser.CAREGIVER
    }

    get address(): Address | undefined {
        return this._address
    }

    set address(value: Address | undefined) {
        this._address = value
    }

    get total_patients(): number | undefined {
        return this._total_patients
    }

    set total_patients(value: number | undefined) {
        this._total_patients = value
    }

    get health_professional_id(): string | undefined {
        return this._health_professional_id
    }

    set health_professional_id(value: string | undefined) {
        this._health_professional_id = value
    }

    public fromJSON(json: any): Caregiver {
        super.fromJSON(json)

        if (json.address !== undefined) {
            this.address = new Address().fromJSON(json.address)
        }

        if (json.total_patients !== undefined) {
            this.total_patients = json.total_patients
        }

        if (json.health_professional_id !== undefined) {
            this.health_professional_id = json.health_professional_id
        }

        return this
    }

    public toJSON(): any {
        return {
            ...super.toJSON(),
            address: this.address ? this.address.toJSON() : undefined,
            total_patients: this.total_patients ? this.total_patients : undefined,
            health_professional_id: this.health_professional_id ? this.health_professional_id : undefined
        }
    }
}
