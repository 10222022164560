import { JsonUtils } from '../../utils/json.util'

export enum FitbitStatus {
    VALID_TOKEN = 'valid_token',
    INVALID_TOKEN = 'invalid_token',
    NONE = 'none'
}

export const MAP_ENUM_FITBIT_STATUS_TRANSLATE = {
    [FitbitStatus.VALID_TOKEN]: 'PATIENTS.EXTERNAL_SERVICES.VALID_TOKEN',
    [FitbitStatus.INVALID_TOKEN]: 'PATIENTS.EXTERNAL_SERVICES.INVALID_TOKEN',
    [FitbitStatus.NONE]: 'PATIENTS.EXTERNAL_SERVICES.NONE'
}

export interface SyncResponse {
    readonly activities: number
    readonly sleep: number
    readonly weights: number
    readonly intraday: {
        readonly steps: number,
        readonly calories: number,
        readonly distance: number,
        readonly active_minutes: number,
        readonly heartrate: number
    }
}

export default class ExternalService {
    // Optional
    private _fitbit_status: FitbitStatus | undefined
    private _fitbit_last_sync: number | undefined

    constructor() {
        this._fitbit_status = '' as FitbitStatus
    }

    get fitbit_status(): FitbitStatus | undefined {
        return this._fitbit_status
    }

    set fitbit_status(value: FitbitStatus | undefined) {
        this._fitbit_status = value
    }

    get fitbit_last_sync(): number | undefined {
        return this._fitbit_last_sync
    }

    set fitbit_last_sync(value: number | undefined) {
        this._fitbit_last_sync = value
    }


    public fromJSON(json: any): ExternalService {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.fitbit_status !== undefined) {
            this.fitbit_status = json.fitbit_status
        }

        if (json.fitbit_last_sync !== undefined) {
            this.fitbit_last_sync = json.fitbit_last_sync
        }

        return this
    }

    public toJSON(): any {
        return {
            fitbit_status: this.fitbit_status ? this.fitbit_status : undefined,
            fitbit_last_sync: this.fitbit_last_sync ? this.fitbit_last_sync : undefined
        }
    }
}
