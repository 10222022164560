import { TypeMeasurement } from '../models/measurements/measurements.last'
import BloodPressure from '../models/measurements/blood.pressure'
import BloodGlucose from '../models/measurements/blood.glucose'
import BodyFat from '../models/measurements/body.fat'
import BodyTemperature from '../models/measurements/body.temperature'
import CalfCircumference from '../models/measurements/calf.circumference'
import HandGrip from '../models/measurements/hand.grip'
import Height from '../models/measurements/height'
import Weight from '../models/measurements/weight'
import WaistCircumference from '../models/measurements/waist.circumference'
import OxygenSaturation from '../models/measurements/oxygen.saturation'
import TSGaitSpeed from '../models/measurements/ts.gait.speed'
import GaitSpeed from '../models/measurements/gait.speed'
import ManualGaitSpeed from '../models/measurements/manual.gait.speed'

export class FactoryMeasurement {

    public static build(values: any, type: TypeMeasurement): any {
        switch (type) {
            case TypeMeasurement.BLOOD_GLUCOSE:
                return new BloodGlucose().fromJSON(values)

            case TypeMeasurement.BLOOD_PRESSURE:
                return new BloodPressure().fromJSON(values)

            case TypeMeasurement.BODY_FAT:
                return new BodyFat().fromJSON(values)

            case TypeMeasurement.BODY_TEMPERATURE:
                return new BodyTemperature().fromJSON(values)

            case TypeMeasurement.CALF_CIRCUMFERENCE:
                return new CalfCircumference().fromJSON(values)

            case TypeMeasurement.HAND_GRIP:
                return new HandGrip().fromJSON(values)

            case TypeMeasurement.HEIGHT:
                return new Height().fromJSON(values)

            case TypeMeasurement.WEIGHT:
                return new Weight().fromJSON(values)

            case TypeMeasurement.WAIST_CIRCUMFERENCE:
                return new WaistCircumference().fromJSON(values)

            case TypeMeasurement.OXYGEN_SATURATION:
                return new OxygenSaturation().fromJSON(values)

            case TypeMeasurement.GAIT_SPEED:
                return new GaitSpeed().fromJSON(values)

            case TypeMeasurement.TS_GAIT_SPEED:
                return new TSGaitSpeed().fromJSON(values)

            case TypeMeasurement.MANUAL_GAIT_SPEED:
                return new ManualGaitSpeed().fromJSON(values)

            default:
                return values
        }
    }
}
