import { MultiStatusItem } from './multi.status.item'
import { JsonUtils } from '../../utils/json.util'
import Entity from '../entity'

export class MultiStatus<T extends Entity> {
    private _success: Array<MultiStatusItem<T>> | undefined
    private _error: Array<MultiStatusItem<T>> | undefined

    get success(): Array<MultiStatusItem<T>> | undefined {
        return this._success
    }

    set success(value: Array<MultiStatusItem<T>> | undefined) {
        this._success = value
    }

    get error(): Array<MultiStatusItem<T>> | undefined {
        return this._error
    }

    set error(value: Array<MultiStatusItem<T>> | undefined) {
        this._error = value
    }

    public fromJSON(json: any): MultiStatus<T> {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.success !== undefined) {
            this.success = json.success.map((item: any) => new MultiStatusItem<T>().fromJSON(item))
        }

        if (json.error !== undefined) {
            this.error = json.error.map((item: any) => new MultiStatusItem<T>().fromJSON(item))
        }

        return this
    }

    public toJSON(): any {
        return {
            success: this.success?.map((item: MultiStatusItem<any>) => item.toJSON()),
            error: this.error?.map((item: MultiStatusItem<any>) => item.toJSON())
        }
    }
}
