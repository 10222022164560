import { JsonUtils } from '../../utils/json.util'

export default class Login {
    // Required
    private _email: string | undefined
    private _password: string | undefined

    get email(): string | undefined {
        return this._email
    }

    set email(value: string | undefined) {
        this._email = value
    }

    get password(): string | undefined {
        return this._password
    }

    set password(value: string | undefined) {
        this._password = value
    }

    public fromJSON(json: any): Login {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.email !== undefined) {
            this.email = json.email
        }

        if (json.password !== undefined) {
            this.password = json.password
        }

        return this
    }

    public toJSON(): any {
        return {
            email: this.email ? this.email : undefined,
            password: this.password ? this.password : undefined
        }
    }
}