import { NoteType } from './note.type'
import Diagnosis from '../models/diagnosis/diagnosis'
import Evolution from '../models/evolution/evolution'

export class FactoryNote {

    public static build(values: any, type: NoteType): any {
        switch (type) {
            case NoteType.DIAGNOSTIC:
                return new Diagnosis().fromJSON(values)

            case NoteType.EVOLUTION:
                return new Evolution().fromJSON(values)

            default:
                return values
        }
    }
}
