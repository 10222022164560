import { JsonUtils } from '../../../utils/json.util'

export default class SleepSummaryData {
    private _count: number | undefined
    private _duration: number | undefined

    get count(): number | undefined {
        return this._count
    }

    set count(value: number | undefined) {
        this._count = value
    }

    get duration(): number | undefined {
        return this._duration
    }

    set duration(value: number | undefined) {
        this._duration = value
    }

    public fromJSON(json: any): SleepSummaryData {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.count !== undefined) {
            this.count =json.count
        }

        if (json.duration !== undefined) {
            this.duration = json.duration
        }

        return this
    }

    public toJSON(): any {
        return {
            count: this.count ? this.count : undefined,
            duration: this.duration ? this.duration : undefined
        }
    }
}
