import { all, apply, put, takeEvery } from 'redux-saga/effects'
import { IActionType } from '../../root.types'
import { IActionSaveGoals, PatientTypes } from './types'

import { saveGoalsFailure } from './actions'
import { SnackBarMessageType } from '../../../../components/snackbar'
import { open } from '../../snack.bar/actions'
import patientService from '../../../../services/users/patient'
import { createSuccess } from '../actions'

function* saveGoals(action: IActionType<IActionSaveGoals>) {
    try {
        const { goals, patientId } = action.payload
        const response: any = yield apply(patientService, patientService.saveGoals, [patientId, goals])
        yield put<any>(createSuccess(response))
        yield put(open(SnackBarMessageType.SUCCESS, '', 'SNACKBAR.UPDATE.MESSAGE'))
    } catch (e) {
        yield put(saveGoalsFailure())
    }
}

export default function* patientSaga() {
    return yield all([takeEvery(PatientTypes.SAVE_GOALS_REQUEST, saveGoals)])
}
