import { JsonUtils } from '../../utils/json.util'

export default class Summary {
    private _total: number | undefined

    get total(): number | undefined {
        return this._total
    }

    set total(value: number | undefined) {
        this._total = value
    }

    public fromJSON(json: any): Summary {
        if (!json) {
            return this
        }

        if (typeof json === 'string') {
            if (!JsonUtils.isJsonString(json)) {
                return this
            }
            json = JSON.parse(json)
        }

        if (json.total !== undefined) {
            this.total = json.total
        }

        return this
    }

    public toJSON(): any {
        return {
            total: this.total ? this.total : undefined
        }
    }
}
